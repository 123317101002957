import React from "react";
import { Asset } from "../../../../models/assets.model";
import { TradingHelper } from "../../../../models/tradinghelper.model";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import AssetItem from "./AssetItem";

interface AssetsMenuProps {
  group_id: number | undefined;
  assetsList: Asset[];
  selectedAsset: Asset | undefined;
  handleSelectedAsset: (asset: Asset) => void;
  handleStateChange: () => void;
  tradingHelperData: TradingHelper[];
}

const AssetsMenu: React.FC<AssetsMenuProps> = ({
  group_id,
  assetsList,
  selectedAsset,
  handleSelectedAsset,
  handleStateChange,
  tradingHelperData,
}) => {
  const getCurrentAssetState = (assetId: number) => {
    return tradingHelperData.find((element) => element.assetId === assetId);
  };

  const makeRecord = async (asset: Asset) => {
    if (!group_id || !asset) return;

    try {
      await responseHero.post(`${process.env.REACT_APP_API_URL}/tradinghelper/${group_id}/${asset.id}`);
      handleStateChange();
      toast.success(`Sikeresen megnyitottad a következőt: ${asset.name}`);
    } catch (error) {
      toast.error(`Sikertelen művelet!`);
    }
  };

  return (
    <div className="px-4">
      <div className="bg-gray-800 shadow-md rounded-lg p-3 w-max">
        <h2 className="text-xl font-semibold text-white mb-2">Eszközök:</h2>
        <div>
          {assetsList.map((asset) => (
            <AssetItem
              key={asset.id}
              asset={asset}
              currentElement={getCurrentAssetState(asset.id)}
              isSelected={selectedAsset === asset}
              onSelect={() => handleSelectedAsset(asset)}
              onMakeRecord={() => makeRecord(asset)}
              handleStateChange={handleStateChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssetsMenu;
