import FormContainer from "./tables/dashboard/FormContainer";
import Container from "./groupsumdata/Container";

export default function Dashboard() {
  return (
    <>
        <div className="flex flex-row space-x-4 p-3">
            <div className="w-1/4">
                <Container/>
            </div>
            <div className="w-3/4">
                <FormContainer/>
            </div>
        </div>
    </>
  );
}