import React from "react";
import { MiningMachine } from "../../models/miningmachine.model"
import { useParams, useNavigate } from 'react-router-dom';
import { getMyMiningMachine } from "../../api/getMiningMachines";
import EnergyCosts from "./miningenergy/EnergyCosts";
import MiningProductionList from "./miningprod/ProductionCosts";
import MiningCoinConversionList from "./miningcoinconversion/MiningCoinConversionList";
import MinedCoinsTransferList from "./minedcoinstransfer/MinedCoinsTransferList";

export default function MyMiningMachine () {
    const { id } = useParams();
    const navigate = useNavigate();

    const [miningMachine, setMiningMachine] = React.useState<MiningMachine>();
    React.useEffect(() => {
        if(!id || id === null || id === undefined) return navigate('/miningmachines');

        getMyMiningMachine(parseInt(id, 10))
        .then((response) => {
            if(response === null || response === undefined || !response) {
                return navigate('/miningmachines');
            }
            setMiningMachine(response);
        })
        .catch((error) => {
            
            console.error(error);

            return navigate('/miningmachines')
        });
        
    }, [id])

    return (
        <div className="container mx-auto p-4">
            <div className="bg-gray-200 p-4 mb-5">
                <h1 className="text-lg">Ez a <span className="text-brown">{miningMachine?.name} ({miningMachine?.miningMachine?.name})</span> <span className="text-sm">(Hash: {miningMachine?.hash})</span> nevű bányagép kezelőfelülete.</h1>
                <button className="hover:opacity-80 shadow-md mt-2 mb-2 bg-dark-blue text-white px-3 py-1" onClick={() => navigate(-1)}>
                    VISSZA A GÉPEKHEZ
                </button>
            </div>
            
            <div className="flex flex-wrap -mx-2 shadow-md shadow-dark">
                <div className="w-1/2 px-2">
                    <div className="bg-gray-200 p-4">
                        <EnergyCosts miningMachine={miningMachine}/>
                    </div>
                </div>

            
                <div className="w-1/2 px-2 shadow-md shadow-dark">
                    <div className="bg-gray-200 p-4">
                    <MiningProductionList miningMachine={miningMachine}/>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap -mx-2 mt-10 shadow-md shadow-dark">
                <div className="w-1/2 px-2">
                    <div className="bg-gray-200 p-4">
                        <MiningCoinConversionList miningMachine={miningMachine}/>
                    </div>
                </div>

                <div className="w-1/2 px-2">
                    <div className="bg-gray-200 p-4">
                        <MinedCoinsTransferList miningMachine={miningMachine}/>
                    </div>
                </div>
            </div>
        </div>


    )

}