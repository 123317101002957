import React, { useEffect, useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/reducers/userSlice";
import { DepositRegister } from "../../../../models/depositregister";

type ModifiedPayload = Omit<DepositRegister, 'user' | 'group_id' | 'group' | 'invested_amount_alt'>;

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    row: DepositRegister | undefined;
}

const ModifyRecordModal = ({ group, row, title, showModal, closeModal, handleStateChange } : Omit<ModalProps, "user">) => {

    const user = useSelector(selectUser);

    const [pickGold, setPickGold] = useState(false);
    const [pickCost, setPickCost] = useState(false);
    const [pickMining, setPickMining] = useState(false);
    const [pickAlt, setPickAlt] = useState(false);
    const [liquidityPercentage, setLiquidityPercentage] = useState<number>(20);

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        id: -1,
        date: new Date(),
        user_id: user.userInfo.user_id,
        invested_amount_eur: 0,
        net_invested_amount_eur: 0,
        invested_amount_usdt: 0,
        deduction_all: 0,
        deduction_physical_gold: 0,
        deduction_mining: 0,
        cost_deduction: 0,
        deduction_alt: 0,
        amount_in_trade: 0,
        liquidity: 0,
        liquidity_paxg: 0,
        fee: 0,
        capital_ratio: 0,
        bonus: 0,
        bonus_amount: 0,
    });
    
    React.useEffect(() => {
        if(!row) return;
        setRecordData({
            id: row.id,
            user_id: row.user_id,
            date: row.date,
            invested_amount_eur: row.invested_amount_eur,
            net_invested_amount_eur: row.net_invested_amount_eur,

            invested_amount_usdt: row.invested_amount_usdt,
            deduction_all: row.deduction_all,
            deduction_physical_gold: row.deduction_physical_gold,
            deduction_mining: row.deduction_mining,
            deduction_alt: row.deduction_alt,

            cost_deduction: row.cost_deduction, // new
            amount_in_trade: row.amount_in_trade, // new

            liquidity: row.liquidity,
            liquidity_paxg: row.liquidity_paxg,
            fee: row.fee,
            capital_ratio: row.capital_ratio,
            bonus: row.bonus,
            bonus_amount: row.bonus_amount,

            })

        // handle checkboxs default states

        /*
            const [pickGold, setPickGold] = useState(true);
            const [pickCost, setPickCost] = useState(true);
            const [pickMining, setPickMining] = useState(true);
            const [pickAlt, setPickAlt] = useState(true);
        */
        
        handlePickStates(row);


    }, [row])

    React.useEffect(() => {
        if(!recordData || !row) return;
        const investedAmount = recordData?.invested_amount_usdt;
    
        const picks = [pickAlt, pickCost, pickGold, pickMining];
        const activePickCount = picks.filter(Boolean).length; // ez komoly
    
        const deductionPercentage = activePickCount * 0.1;
        const newData = Math.round(investedAmount * deductionPercentage);
    
        setRecordData(prevData => ({
            ...prevData,
            deduction_all: newData
        }));
    }, [pickAlt, pickCost, pickGold, pickMining]);
    
    

    // thanks to ChatGPT 4.0
    const handlePickStates = (row: any) => {
        const states = [
            { value: row.cost_deduction, setter: setPickCost },
            { value: row.deduction_mining, setter: setPickMining },
            { value: row.deduction_alt, setter: setPickAlt },
            { value: row.deduction_physical_gold, setter: setPickGold },
        ];
    
        states.forEach(({ value, setter }) => {
            setter(value > 0);
        });
    };
    

    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
    
        // Check if the input is a valid float with two decimals for rate_btc and rate_eth

        // For other types or invalid input, handle them as before

        if(type === "bonus") {
            setRecordData(prevData => ({
                ...prevData,
                [type]:  parseInt(inputValue, 10)
            }));

            setRecordData(prevData => ({
                ...prevData,
                "bonus_amount": Math.round(recordData?.invested_amount_eur * (parseInt(inputValue, 10) / 100)) || 0
            }));
        }
        else if(type === "bonus_amount") {
            setRecordData(prevData => ({
                ...prevData,
                [type]:  parseInt(inputValue, 10)
            }));

            setRecordData(prevData => ({
                ...prevData,
                "bonus": Math.round((parseInt(inputValue, 10) / recordData?.invested_amount_eur) * 100) || 0
            }));
        } else {
            setRecordData(prevData => ({
                ...prevData,
                [type]: (type === "date") ? inputValue : parseInt(inputValue, 10) || 0
            }));
        }
        
    };


    const updateRecord = async () => {

        if(!group || !row) return;

        await responseHero.put(`${process.env.REACT_APP_API_URL}/humandc/deposit/${row?.id}`, { depositRegistration: recordData, metaDataValues: {
            pickGold: pickGold,
            pickCost: pickCost,
            pickMining: pickMining,
            pickAlt: pickAlt,
            liquidityPercentage: liquidityPercentage
        }}).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen módosítottad a rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title} | {recordData?.date?.toString().split('T')[0]} <span className="text-dark-blue">| Beutalt összeg: </span>{recordData?.invested_amount_eur} EUR</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">

                                    <div className="w-1/4 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Dátum </span> <br/>Beutalás dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                    </div>

                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Tőkeviszonyszám </span> <br/>EUR
                                        </label>
                                        <input
                                            value={recordData?.capital_ratio}
                                            onChange={(e) => handleChange('capital_ratio', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Tőkeviszonyszám EUR-ban"/>
                                    </div>

                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bónusz </span> <br/>%
                                        </label>
                                        <input
                                            value={recordData?.bonus}
                                            onChange={(e) => handleChange('bonus', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Bónusz % -ban"/>
                                    </div>

                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bónusz összege</span> <br/>EUR
                                        </label>
                                        <input
                                            value={recordData?.bonus_amount}
                                            onChange={(e) => handleChange('bonus_amount', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Bónusz % -ban"/>
                                    </div>
                                </div>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Befizetett összeg </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.invested_amount_usdt}
                                            onChange={(e) => handleChange('invested_amount_usdt', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Befizetett összeg USDT-ben"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Likviditás </span> <br/>Százalék
                                        </label>
                                        <input
                                            value={liquidityPercentage}
                                            onChange={(e) => setLiquidityPercentage(parseInt(e.target.value, 10))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Befizetett összeg USDT-ben"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Fee </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.fee}
                                            onChange={(e) => handleChange('fee', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Fee USDT-ben"/>
                                    </div>

                                </div>

                                <div className="flex mt-2 p-2 bg-cream py-3">
                                <div className="w-full pr-2 text-center items-center">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                    <span className="text-success">Összes elvonás </span> <br/>Költségek - Arany - Bányagép - Altcoin
                                    </label>
                                    <input
                                        value={recordData.deduction_all}
                                        onChange={(e) => handleChange('deduction_all', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="inpayment" type="number" placeholder="EUR"/>
                                </div>
                                </div>

                                {/* SECOND PART */}
                                <div className="flex p-2 rounded shadow-md bg-cream py-3" >
                                    

                                    <div className="w-1/4 pr-2 flex flex-row text-center items-center">
                                        <label className="text-sm font-bold text-brown" htmlFor="crypto_1">
                                            <span className="text-success">Levonás</span> <br/>ARANY
                                        </label>
                                        <input 
                                            id="crypto_1" 
                                            type="checkbox" 
                                            className="ml-2 w-8 h-8 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" 
                                            checked={pickGold}
                                            onChange={() => setPickGold(!pickGold)}
                                        />
                                    </div>

                                    <div className="w-1/4 pr-2 flex flex-row text-center items-center">
                                        <label className="text-sm font-bold text-brown" htmlFor="crypto_1">
                                            <span className="text-success">Levonás</span> <br/>KÖLTSÉG
                                        </label>
                                        <input 
                                            id="crypto_1" 
                                            type="checkbox" 
                                            className="ml-2 w-8 h-8  border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" 
                                            checked={pickCost}
                                            onChange={() => setPickCost(!pickCost)}
                                        />
                                    </div>

                                    <div className="w-1/4 pr-2 flex flex-row text-center items-center">
                                        <label className="text-sm font-bold text-brown" htmlFor="crypto_1">
                                            <span className="text-success">Levonás</span> <br/>BÁNYAGÉP / ENERGIA
                                        </label>
                                        <input 
                                            id="crypto_1" 
                                            type="checkbox" 
                                            className="ml-2 w-8 h-8 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" 
                                            checked={pickMining}
                                            onChange={() => setPickMining(!pickMining)}
                                        />
                                    </div>

                                    <div className="w-1/4 pr-2 flex flex-row text-center items-center">
                                        <label className="text-sm font-bold text-brown" htmlFor="crypto_1">
                                            <span className="text-success">Levonás</span> <br/>ALTCOIN
                                        </label>
                                        <input 
                                            id="crypto_1" 
                                            type="checkbox" 
                                            className="ml-2 w-8 h-8 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" 
                                            checked={pickAlt}
                                            onChange={() => setPickAlt(!pickAlt)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={updateRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default ModifyRecordModal;