import { TradingHelper } from "../models/tradinghelper.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getTradingHelperDataByGroup = (group_id: number, assetId?: number): Promise<TradingHelper[]> => {
    return new Promise<TradingHelper[]>((resolve, reject) => {
        if(!group_id) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/tradinghelper/${group_id}/${assetId}`).then((response) => {
            resolve(response.data as TradingHelper[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};