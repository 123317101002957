import { useEffect, useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { PhysicalGold } from "../../../../models/physicalgold.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/reducers/userSlice";
import { LiqudityTransfer } from "../../../../models/liqtrans.model";
import React from "react";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    row: LiqudityTransfer | undefined;
}

type ModifiedPayload = Pick<LiqudityTransfer, 
"amount" |
"caption" |
"start_date" |
"end_date" |
"operation">

const ModifyRecordModal = ({ group, title, showModal, closeModal, handleStateChange, row } : ModalProps) => {

    const user = useSelector(selectUser);

    const [operation, setOperation] = React.useState<boolean>(false);

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        caption: "",
        amount: 0,
        start_date: new Date(),
        end_date: new Date(),
        operation: false
    });
    
    useEffect(() => {
        if(!row) return;

        setRecordData({
            amount: row.amount,
            caption: row.caption,
            start_date: new Date(),
            end_date: new Date(),
            operation: row.operation
        });
    }, [row])

    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if ((type === "caption"  || type === "start_date" || type === "end_date")) {
            setRecordData(prevData => ({
                ...prevData,
                [type]: inputValue
            }));
        }
        if(type === "amount") {
            setRecordData(prevData => ({
                ...prevData,
                [type]: parseInt(inputValue)
            }));
        }

        if (type === "operation") {
            setOperation(!operation);
            setRecordData((prevData) => ({
            ...prevData,
            [type]: !operation,
            }));
        }
    };
    const makeRecord = async () => {

        if(!group || !row) return;
        await responseHero.put(`${process.env.REACT_APP_API_URL}/humandc/dashboard/liqtransfers/${group.group_id}/${row.id}`, { liqTransfer: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen módosítottál egy rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                        <form>
                            <div className="flex p-2 rounded shadow-md">
                                
                            <div className="w-1/5 pr-2 text-center items-center">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-brown">Típus </span>
                                    </label>
                                    <div className="mt-2">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                value="option1"
                                                checked={operation === true}
                                                onChange={(e) => handleChange('operation', e)}
                                                className="form-radio h-5 w-5 text-brown"
                                            />
                                            <span className="ml-2 text-success">ÉRKEZETT</span>
                                        </label>
                                        <label className="inline-flex items-center ml-6">
                                            <input
                                                type="radio"
                                                value="option2"
                                                checked={operation === false}
                                                onChange={(e) => handleChange('operation', e)}
                                                className="form-radio h-5 w-5 text-brown"
                                            />
                                            <span className="ml-2 text-error">ELVONÁS</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="w-1/5 pr-2 text-center items-center">
                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Likviditás</span> <br/>Dátum kezdés
                                    </label>
                                    <input
                                        value={recordData?.start_date.toString().split('T')[0]}
                                        onChange={(e) => handleChange('start_date', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                </div>

                                <div className="w-1/5 pr-2 text-center items-center">
                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Likviditás</span> <br/>Dátum végzés
                                    </label>
                                    <input
                                        value={recordData?.end_date.toString().split('T')[0]}
                                        onChange={(e) => handleChange('end_date', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                </div>

                                
                                <div className="w-1/5 pr-2 text-center items-center">
                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Likviditás</span> <br/>Összeg (USD)
                                    </label>
                                    <input
                                        value={recordData?.amount}
                                        onChange={(e) => handleChange('amount', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="amount" type="number" placeholder="Összeg (USD)"/>
                                </div>

                                <div className="w-1/5 pr-2 text-center items-center">
                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Likviditás</span> <br/>Megjegyzés
                                    </label>
                                    <input
                                        value={recordData?.caption}
                                        onChange={(e) => handleChange('caption', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="caption" type="text" placeholder="Megjegyzés"/>
                                </div>

                            </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default ModifyRecordModal;