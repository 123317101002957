import React, { useEffect, useState } from "react";
import { ModalProps } from "../../../types/modalProps";
import { User } from "../../../models/user.model";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserState } from "../../../store/reducers/selectedUserSlice";

type ModifiedPayload = Omit<User, "user_id" |"last_logged" | "admin" | "password" | "network" | "network_address" | "exchange">

const CreateUserModal = ({ title, user, showModal, closeModal, handleStateChange } : ModalProps) => {

    const [changePasswordVisibility, setChangePasswordVisibility] = React.useState<boolean>(false);
    const select = useSelector(selectUserState)

    const [newUserData, setNewUserData] = useState<ModifiedPayload>({
        name: '',
        email: '',
        phone: '',
        card_number: '',
        bank_name: '', 
        address: '',
        location: '',
        birthplace: '',
        birthdate: new Date(),
        mother_name: '',
        id_card: ''
      });
      
      useEffect(() => {
        if (user) {
          setNewUserData({
            name: "",
            email: "",
            phone: "0",
            card_number: "0",
            bank_name: "", 
            address: "", 
            location: "Magyarország",
            birthplace: "",
            birthdate: new Date(),
            mother_name: "",
            id_card: "",
          });
        }
      }, [user]);

    const createUser = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/users`, { user: newUserData }).then((response) => {
            closeModal()
            handleStateChange();

            toast.success(`Sikeresen létrehoztad a következőt: ${newUserData.name}`);

            // reset user data state
            setNewUserData({
                name: "",
                email: "",
                phone: "0",
                card_number: "0",
                bank_name: "", 
                address: "", 
                location: "Magyarország",
                birthplace: "",
                birthdate: new Date(),
                mother_name: "",
                id_card: "",
            });
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error(`Sikertelen hozzáadás!`);
        })
    }

    const handleChange = (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUserData(prevData => ({
            ...prevData,
            [type]: e.target.value,
        }));
    };

    return (
    <>
        {showModal && (
            <>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-dark-blue font=semibold"><b>{user?.name}</b> {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <div className="flex p-4 rounded shadow-md">

                                <div className="w-1/3 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="username">
                                        Név
                                    </label>
                                        <input value={newUserData.name} onChange={(e) => handleChange('name', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Vezetéknév Keresztnév"/>
                                    
                                    </div>
                                    <div className="w-1/3 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="email">
                                        Email
                                    </label>
                                        <input value={newUserData.email} onChange={(e) => handleChange('email', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email cím"/>
                                    </div>
                                    <div className="w-1/3 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="phone">
                                        Telefonszám
                                    </label>
                                        <input value={newUserData.phone} onChange={(e) => handleChange('phone', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Telefonszám"/>
                                    </div>
                            </div>
                            <div className="flex p-4 rounded shadow-md">

                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="card">
                                        Bankszámlaszám
                                    </label>
                                        <input value={newUserData.card_number} onChange={(e) => handleChange('card_number', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Bankszámlaszám"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="bank_name">
                                        Bank neve
                                    </label>
                                        <input value={newUserData.bank_name} onChange={(e) => handleChange('bank_name', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="bank_name" type="text" placeholder="Bank neve"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">

                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="birthplace">
                                        Születési hely
                                    </label>
                                    <input value={newUserData.birthplace} onChange={(e) => handleChange('birthplace', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="birthplace" type="text" placeholder="Születési hely"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="birthdate">
                                        Születési idő
                                    </label>
                                    <input value={newUserData.birthdate.toString().split('T')[0]} type="date" onChange={(e) => handleChange('birthdate', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="birthdate" placeholder="Születési idő"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="address">
                                Lakcím
                                </label>
                                <input value={newUserData.address} onChange={(e) => handleChange('address', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="address" type="text" placeholder="Lakcím"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="location">
                                Tartózkodási ország
                                </label>
                                <input value={newUserData.location} onChange={(e) => handleChange('location', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Tartózkodási ország"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="mother_name">
                                Anyja neve
                            </label>
                                <input type="text" value={newUserData.mother_name} onChange={(e) => handleChange('mother_name', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="mother_name" placeholder="Anyja neve"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="id_card">
                                Személyi igazolvány száma
                            </label>
                                <input type="text" value={newUserData.id_card} onChange={(e) => handleChange('id_card', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="id_card" placeholder="SZIG szám"/>
                                </div>
                            </div>  
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={createUser}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default CreateUserModal;