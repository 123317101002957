import React, { useEffect, useState } from "react";
import { responseHero } from "../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { NewsLetter } from "../../models/newsletter.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/reducers/userSlice";
import SendingNewsLetterModal from "../../components/newsletter/modals/SendingNewsLetterModal";
import NewsLetterSendedModal, { NodeMailerResponse } from "../../components/newsletter/modals/FinishedNewsLetterModal";
import FinishedNewsLetterModal from "../../components/newsletter/modals/FinishedNewsLetterModal";
import { v4 as uuidv4 } from 'uuid';

interface Video {
    link: string;
    title: string;
}

type ModifiedNewsLetterType = Pick<NewsLetter, "topic" | "message" | "templateFileName" | "creatorId">;

export default function NewsLetterPanel() {

    const user = useSelector(selectUser);
    
    const [subject, setSubject] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [selectedNewsLetterTemplate, setSelectedNewsLetterTemplate] = useState<number>(0); // default number of videos is 4
    const [videos, setVideos] = useState<Video[]>(() => {
        const savedVideos = localStorage.getItem('videos');
        return savedVideos ? JSON.parse(savedVideos) : Array.from({ length: 4 }, () => ({ link: "", title: "" }));
    });
    const [htmlTemplate, setHtmlTemplate] = useState<string>("");
    const [sendNewsLetterLoader, setSendNewsLetterLoader] = React.useState<boolean>(false);
    const [showFinishedModal, setShowFinishedModal] = React.useState<boolean>(false);
    const [latestResponse, setLatestResponse] = React.useState<NodeMailerResponse[]>([]);

    const closeModal = () => {
        setShowFinishedModal(false);
    }

    useEffect(() => {
        // Fetch the HTML template on component mount
        fetch('./newsletter/nletter.html')
            .then(response => response.text())
            .then(data => setHtmlTemplate(data));
    }, []);

    useEffect(() => {
        localStorage.setItem('videos', JSON.stringify(videos));
    }, [videos]);

    useEffect(() => {
        const savedVideos = localStorage.getItem('videos');
        if(!savedVideos) return;
        const parsedSavedVideos = JSON.parse(savedVideos);
        setSelectedNewsLetterTemplate(parsedSavedVideos.length);
        
    }, [])

    const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
    };
    
    const downloadHtmlTemplate = async () => {
        const element = document.createElement("a");
        const file = new Blob([htmlTemplate], { type: "text/html" });
        element.href = URL.createObjectURL(file);
        element.download = "newsletter_template.html";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    };

    const handleVideoCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const count = parseInt(e.target.value, 10);
        setVideos(prevVideos => {
            const newVideos: Video[] = Array.from({ length: count }, (_, i) => prevVideos[i] || { link: "", title: "" });
            return newVideos;
        });
        setSelectedNewsLetterTemplate(count);
    };
    

    const handleInputChange = (index: number, field: keyof Video, value: string) => {
        const newVideos = [...videos];
        newVideos[index] = { ...newVideos[index], [field]: value };
        setVideos(newVideos);
    };

    const extractYouTubeID = (url: string) => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const sendNewsLetter = async () => {
        setSendNewsLetterLoader(true)
        var successSending = false;

        await responseHero.post(`${process.env.REACT_APP_API_URL}/sendNewsletter`, {
            email: {
                from: process.env.REACT_APP_NEWSLETTER_SENDER,
                password: process.env.REACT_APP_NEWSLETTER_PASSWORD,
                message: htmlTemplate,
                subject: subject
            }
        }).then((response) => {
            toast.success("Sikeresen elküldted a hírlevelet!")

            setSendNewsLetterLoader(false)
            setLatestResponse(response.data)

            setShowFinishedModal(true);

            successSending = true;
        }).catch(error => {
            console.error(error.response.data);
            toast.error("Hiba csúszott a hírlevél küldése közben!")
            setSendNewsLetterLoader(false)

            setShowFinishedModal(true);

            successSending = false;
        });

        if(successSending) {

            const uniqueId = uuidv4();

            const newsLetterObject:ModifiedNewsLetterType  = {
                topic: subject,
                message: content,
                templateFileName: `${uniqueId}-template.html`,
                creatorId: user?.userInfo?.user_id
            }
    
            await insertNewNewsLetterTemplateInToDB(newsLetterObject, htmlTemplate);
        }
    }

    const generateVideoHTML = () => {
        let videoHTML = "";
        videos.forEach((video, index) => {
            const videoID = extractYouTubeID(video.link);
            if (videoID) {
                if (index % 2 === 0 && index !== 0) {
                    videoHTML += "</tr><tr>"; // Új sor kezdése minden második videó után
                }
                videoHTML += `
                    <td style="padding-left: 10px; text-align:center;">
                        <a href="${video.link}" target="_blank" style="text-decoration: none;">
                            <img src="https://img.youtube.com/vi/${videoID}/hqdefault.jpg" alt="Video ${index + 1}" style="width: 300px; display: block; margin: 0 auto;">
                        </a>
                        <p style="margin: 10px 0 0; text-align: center;">${video.title}</p>
                    </td>
                `;
            }
        });
    
        return `<tr>${videoHTML}</tr>`; // Körülöleljük a generált HTML-t egy sorral
    };

    const generateHTMLContent = () => {

        // check subject

        if(subject.length < 15) {
            return toast.warn("A hírlevél tárgya túl rövid! Minimum: 15 karakter szükséges.");
        }

        if(content.length < 100 || content.length > 400) {
            return toast.warn("A hírlevél szövegének 100 és 400 karakter közé kell esnie.");
        }

        const hasEmptyFields = videos.some(video => !video.link || !video.title);
        if(hasEmptyFields) {
            return toast.warn("Még van kitöltetlen videó cím vagy link.");
        }

        const videoHTML = generateVideoHTML();
        const beginFix = "<!-- BEGIN FIX -->";
        const endFix = "<!-- END FIX -->";
    
        const contentStart = "<!-- CONTENT START -->";
        const contentEnd = "<!-- CONTENT END -->";
    
        const beginIndex = htmlTemplate.indexOf(beginFix) + beginFix.length;
        const endIndex = htmlTemplate.indexOf(endFix);
    
        const contentStartIndex = htmlTemplate.indexOf(contentStart) + contentStart.length;
        const contentEndIndex = htmlTemplate.indexOf(contentEnd);
    
        if (beginIndex === -1 || endIndex === -1) {
            console.error("BEGIN FIX or END FIX comments not found in the template.");
            return;
        }
    
        if (contentStartIndex === -1 || contentEndIndex === -1) {
            console.error("CONTENT START or CONTENT END comments not found in the template.");
            return;
        }
    
        const newHtmlContent = htmlTemplate.slice(0, beginIndex) + videoHTML + htmlTemplate.slice(endIndex);
        const finalHtmlContent = newHtmlContent.slice(0, contentStartIndex) + content + newHtmlContent.slice(contentEndIndex);
    
        setHtmlTemplate(finalHtmlContent);
    };

    const insertNewNewsLetterTemplateInToDB  = async (newsLetter: ModifiedNewsLetterType, htmlTemplate: string) => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/newsletter`, { newsLetter: newsLetter, htmlTemplate: htmlTemplate }).then((response) => {
            toast.success("Sikeres a hírlevélsablon mentése!");
        }).catch(error => {
            toast.error("Sikertelen a hírlevélsablon mentése!");
        })
    }
    

    return (
        <>
            <SendingNewsLetterModal title={"Hírlevél küldése"} showModal={sendNewsLetterLoader} sendingStatus={false}/>
            <FinishedNewsLetterModal title={"Hírlevél elküldve"} showModal={showFinishedModal} responses={latestResponse} closeModal={closeModal}/>
            
            <div className="m-5 p-3">
                <div className="shadow-md w-full">
                    <p className="text-xl text-center mb-2 mt-2">Hírlevél - Küldő: <span className="font-semibold">Készítsd el a sablont a videókkal (Sablon előállítása), majd ha megfelelő: Hírlevél küldése</span></p>
                </div>

                <div className="flex space-x-4 overflow-x-auto text-dark-blue">
                    
                    <div className="w-1/2 text-center bg-white shadow-md rounded p-4">
                    <div className="mb-4 p-4 bg-gray-200 shadow-md rounded-lg">
                        <label className="block text-gray-700 text-sm font-bold mb-2">A hírlevél tárgya:</label>
                        <input
                            type="text"
                            name="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />

                        <label className="block text-gray-700 text-sm font-bold mt-4 mb-2">A hírlevél szövege:</label>
                        <textarea
                            rows={4}
                            name="message"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />

                        <label className="block text-gray-700 text-sm font-bold mt-4 mb-2">Hány videóból áll a hírlevél? (0-5)</label>
                        <div className="flex justify-center space-x-4">
                            {[0, 1, 2, 3, 4, 5].map((count) => (
                            <label key={count} className="inline-flex items-center">
                                <input
                                type="radio"
                                name="videoCount"
                                value={count}
                                checked={selectedNewsLetterTemplate === count}
                                onChange={handleVideoCountChange}
                                className="form-radio h-4 w-4 text-blue-600"
                                />
                                <span className="ml-2 text-gray-700">{count}</span>
                            </label>
                            ))}
                        </div>
                        </div>

                        {videos.map((video, index) => (
                        <div key={index} className="flex bg-gray-200 items-center justify-center space-x-8 mb-2 p-4 shadow-md rounded-lg">

                            <div className="">
                            <label htmlFor={`videoTitle${index}`} className="block text-gray-700 text-sm font-bold mb-1">Cím</label>
                            <input
                                type="text"
                                id={`videoTitle${index}`}
                                name={`videoTitle${index}`}
                                value={video.title}
                                onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            </div>

                            <div className="">
                            <label htmlFor={`videoLink${index}`} className="block text-gray-700 text-sm font-bold mb-1">YouTube videó linkje</label>
                            <input
                                type="text"
                                id={`videoLink${index}`}
                                name={`videoLink${index}`}
                                value={video.link}
                                onChange={(e) => handleInputChange(index, 'link', e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            </div>
                            
                        </div>
                        ))}

                        <button className="h-12 bg-dark-blue hover:opacity-70 text-white px-2 py-2" onClick={generateHTMLContent}>
                            Sablon előállítása 
                        </button>
                    </div>

                    <div className="w-1/2 text-center bg-white shadow-md rounded">
                        <iframe
                            srcDoc={htmlTemplate}
                            className="w-full h-full"
                            title="Newsletter Content"
                        ></iframe>
                    </div>
                </div>

                <div className="flex items-center justify-center mt-10 space-x-4">
                    
                    <button onClick={downloadHtmlTemplate} className={`bg-success hover:opacity-70 text-white px-2 py-2`}>
                        Sablon letöltése
                    </button>
                
                    <button disabled={sendNewsLetterLoader ? true : false} onClick={sendNewsLetter} className={`${sendNewsLetterLoader ? 'bg-gray-800' : 'bg-success'} hover:opacity-70 text-white px-2 py-2`}>
                        {sendNewsLetterLoader ? 'Hírlevél küldése, kérlek várj ...' : 'Hírlevél elküldése'}
                    </button>
                </div>
            </div>
        </>
    );
}
