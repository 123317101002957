import React from "react";
import { MinedCoinsTrading } from "../../../../models/minedcoinstrading.model";
import { getMinedCoinsTrading, getMinedCoinsTransferHistory, MinedCoinsTradeWithTotals } from "../../../../api/getMinedCoinsTrading";
import { ExtendedResposne } from "../../../../api/getMinedCoinsTrading";
import AddNewRecordModal from "./modals/AddNewRecordModal";
import RemoveRecordModal from "./modals/RemoveRecordModal";
export default function MinedCoinTraderPanel () {
    
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"

    const [records, setRecords] = React.useState<MinedCoinsTradeWithTotals>();
    const [history, setHistory] = React.useState<ExtendedResposne>();

    const [addModal, setAddModal] = React.useState<boolean>(false);
    const [rmModal, setRmModal] = React.useState<boolean>(false);

    const [stateChange, setStateChange] = React.useState<boolean>(false);

    const [operation, setOperation] = React.useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = React.useState<MinedCoinsTrading>();
    
    React.useEffect(() => {
        getMinedCoinsTrading()
            .then((response) => {
                setRecords(response)
            })
            .catch((error) => {
                console.error(error);
            });
    }, [stateChange]);

    React.useEffect(() => {
        getMinedCoinsTransferHistory()
        .then((response) => {
            setHistory(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const closeModal = () => {
        setAddModal(false);
        setRmModal(false);
    }

    const openAddModal = (operation: boolean) => {
        setOperation(operation);
        setAddModal(true);
    }

    const openModifyRecordModal = (row: MinedCoinsTrading) => {
        setSelectedRecord(row);
        setRmModal(true);
    }

    const openRemoveRecordModal = (row: MinedCoinsTrading) => {
        setSelectedRecord(row);
        setRmModal(true);
    }

    function handleStateChange() {
        setStateChange(!stateChange);
    }

    return (
        <>
        <div className="w-full overflow-x-auto text-white h-full">

            <div className="m-5 bg-dark-blue px-4 py-4 rounded-lg shadow-dark-blue shadow-sm">
                <div className="mb-2">
                    <p className="text-xl text-white">Összesen át-transzferált coin-mennyiség a bányagép csoportoktól: 
                    <span className="text-cream font-semibold"> {history?.total}</span></p>
                </div>

                <div className="mb-2 flex flex-row space-x-4">
                    <button onClick={() => openAddModal(true)} className="px-4 py-2 rounded hover:shadow-md hover:shadow-white bg-success opacity-80 hover:opacity-100 text-white text-lg">
                        Vásárlás
                    </button>
                    <button onClick={() => openAddModal(false)} className="px-4 py-2 rounded hover:shadow-md hover:shadow-white bg-error opacity-80 hover:opacity-100 text-white text-lg">
                        Eladás
                    </button>
                    <button className="px-4 py-2 rounded hover:shadow-md hover:shadow-white bg-brown opacity-80 hover:opacity-100 text-white text-lg">
                        Transzferálási előzmények
                    </button>
                </div>

                <div>
                    <p className="bg-gray-600 px-2 py-2 w-max rounded-lg text-md">Jelenlegi mennyiség: <span className="text-cream">{records?.totalQuantity}</span> Jelenlegi likviditás: <span className="text-cream">{records?.totalLiq}</span></p>
                </div>

            </div>
            
            <AddNewRecordModal operation={operation} title={`${operation ? 'Vásárlás' : 'Eladás'}: Adatsor rögzítése`} showModal={addModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            <RemoveRecordModal row={selectedRecord} title={"Adatsor törlése"} showModal={rmModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            {/*
           
          
            <ModifyRecordModal row={selectedRecord} asset_id={selectedAsset?.id} title={"Adatsor módosítása"} group={menu.daisyMenu} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            <RemoveRecordModal row={selectedRecord} title={"Adatsor törlése"} group={menu.daisyMenu} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>

            */}
            <div className="p-3">
                <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                    <table className="w-full shadow-lg text-sm border-white border-[10px]">
                        <thead className="bg-gray-800 sticky top-0">
                            <tr>
                                <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                                <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                                <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                                <th className="border p-2 hover:opacity-80 cursor-pointer">Darabszám</th>
                                <th className="border p-2 hover:opacity-80 cursor-pointer">Árfolyam USDT</th>
                                <th className="border p-2 hover:opacity-80 cursor-pointer">Összeg USDT</th>
                                <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Törlés</span></th>
                            </tr>
                        </thead>
                        <tbody className="bg-dark-blue text-white">
                            {records?.tradingList.map((record, index) => (
                                <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                    <td className={dataRowStyling}>{record.id}</td>
                                    <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                    <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>{record.operation ? "BUY" : "SELL"}</td>
                                    <td className={dataRowStyling}>{record?.quantity}</td>
                                    <td className={dataRowStyling}>{record?.exchange_rate}</td>
                                    <td className={dataRowStyling}>{record?.amount}</td>

                                    <td className={dataRowStyling}>
                                        <p onClick={() => {
                                            openRemoveRecordModal(record)
                                        }} className="text-error hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Visszavonás</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
}