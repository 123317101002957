import React, { Fragment } from "react";
import { Asset } from "../../../../models/assets.model";
import { TradingHelper } from "../../../../models/tradinghelper.model";
import { ArrowIcon } from "../../../../styles/assets/svgs/ArrowIcon";
import { ModifyTradingHelperDataModal } from "./modals/ModifyTradingHelperDataModal";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";

interface AssetItemProps {
  asset: Asset;
  currentElement: TradingHelper | undefined;
  isSelected: boolean;
  onSelect: () => void;
  onMakeRecord: () => void;
  handleStateChange: () => void;
}

const AssetItem: React.FC<AssetItemProps> = ({ asset, currentElement, isSelected, onSelect, onMakeRecord, handleStateChange }) => {
  const [showConf, setShowConf] = React.useState(false);
  const menu = useSelector(selectMenuStates);

  const openConf = () => {
    setShowConf(true);
  }

  const hideConf = () => {
    setShowConf(false);
  }

  return (
    <Fragment>
        <ModifyTradingHelperDataModal handleStateChange={handleStateChange} title={"Konfiguráció"} group={menu?.daisyMenu} showModal={showConf} closeModal={hideConf} row={currentElement} asset={asset}/>
        <div
            className={`flex flex-row space-x-4 px-2 py-1 rounded items-center mb-2 transition-transform cursor-pointer ${
                isSelected ? "bg-gray-600" : "bg-gray-700"
            } hover:scale-105 hover:shadow-xl hover:bg-gray-600`}
            onClick={onSelect}
        >
            <img
                src={`https://www.coinlore.com/img/${asset?.img}.webp`}
                alt={asset.name}
                className="w-6 h-6"
            />
            <p className="text-white text-sm font-semibold">{asset.name}</p>

            {currentElement ? (
                <div className="flex flex-row items-center justify-center">
                    <ArrowIcon color={currentElement?.change < 0 ? "#C70039" : "#50C878"} rotation={currentElement?.change >= 0} />
                    <p className={`${currentElement?.change < 0 ? "text-error" : "text-success"} text-lg font-semibold`}>
                        {currentElement.change ? currentElement?.change : 0}%
                    </p>
                </div>
            ) : null}

            {/* Gombok jobb oldalra rendezése */}
            <div className="flex ml-auto space-x-2">
                {!currentElement && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onMakeRecord();
                        }}
                        className="cursor-pointer hover:shadow-md hover:shadow-white border border-gray-600 px-2 py-2 bg-dark-blue text-white rounded-lg"
                    >
                        Megnyitás
                    </button>
                )}

                {currentElement && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            openConf();
                        }}
                        className="cursor-pointer hover:shadow-md hover:shadow-white border border-gray-600 px-2 py-2 bg-dark-blue text-white rounded-lg"
                    >
                        Konfigurálás
                    </button>
                )}
            </div>
        </div>

    </Fragment>
  );
};

export default AssetItem;
