import { User } from "../../models/user.model";

interface selectedUserProps {
    select: User | undefined;
}
export default function SelectedUser ({ select } : selectedUserProps) {
    return (
        <>
        {select ? <p>Hozzárendelve a szerződéshez: <span className="text-cream">{select?.name}</span></p> : <p>Jelenleg nincs hozzárendelve ügyfél</p>}
        {select ?
        <table className="border border-2 w-full text-center mt-2 p-2">
            <tr className="border">
                <td className="border p-2 font-bold">
                    Név / Cégnév:
                </td>
                <td className="border p-2 text-cream">
                    {select?.name}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Lakcím / Székhely:
                </td>
                <td className="border p-2 text-cream">
                    {select?.address}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Tartózkodási ország:
                </td>
                <td className="border p-2 text-cream">
                    {select?.location}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Szül. hely és dátum:
                </td>
                <td className="border p-2 text-cream">
                    {select?.birthplace}, {select?.birthdate.toString().split('T')[0]}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Édesanyja születéskori neve:
                </td>
                <td className="border p-2 text-cream">
                    {select?.mother_name}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Személyi igazolvány száma:
                </td>
                <td className="border p-2 text-cream">
                    {select?.id_card}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Bank neve és számlaszáma:
                </td>
                <td className="border p-2 text-cream">
                    {select?.bank_name}, {select?.card_number}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Kriptováltó neve:
                </td>
                <td className="border p-2 text-cream">
                    {select?.exchange}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Kripto hálózati cím:
                </td>
                <td className="border p-2 text-cream">
                    {select?.network_address}
                </td>
            </tr>
        </table>
        : null
        }
        </>
    )
}