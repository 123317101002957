import React from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getCryptoDepositByGroupAndAsset, getCryptoRecords } from "../../../../../api/getHumanDCFunctions";
import AddNewRecordModal from "../../../modals/crypto/AddNewRecordModal";
import { Crypto } from "../../../../../models/crypto.model";
import ModifyRecordModal from "../../../modals/crypto/ModifyRecordModal";
import RemoveRecordModal from "../../../modals/crypto/RemoveRecordModal";
import { selectedAssetState } from "../../../../../store/reducers/selectedAssetSlice";
import { CryptoDeposit } from "../../../../../models/cryptodeposit.model";
import { Assets } from "../../../../../enums/assets";
import Decimal from "decimal.js";
import { AssetPurchase } from "../../../../../models/assetpurchase.model";
import { getAssetPurchaseData } from "../../../../../api/getAssetPurchaseData";
import { useNavigate } from "react-router";
export interface CryptoRecords {
    rows: Crypto[] | undefined;
    quantity: number;
    value: number;
}

export default function FormContainer () {

    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showModifyRecordModal, setShowModifyRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [showRemoveRecordModal, setShowRemoveRecordModal] = React.useState<boolean>(false); // remove record modal state


    const [records, setRecords] = React.useState<CryptoRecords>();
    const [total, setTotal] = React.useState({ quantity: 0, value: 0 })
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)
    const [selectedRecord, setSelectedRecord] = React.useState<Crypto>();

    const [cryptoDeposits, setCryptoDeposits] = React.useState<CryptoDeposit[]>([]); // Crypto Deposit
    const [assetPurchases, setAssetPurchases] = React.useState<AssetPurchase[]>([]); // Asset Purchase

    const selectedAsset = useSelector(selectedAssetState);

    const menu = useSelector(selectMenuStates)
    const navigate = useNavigate();

    const openModal = () => {
        setShowNewRecordModal(true)
    };

    const openModifyRecordModal = (record: Crypto) => {
        setSelectedRecord(record)
        setShowModifyRecordModal(true)
    };

    const openRemoveRecordModal = (record: Crypto) => {
        setSelectedRecord(record)
        setShowRemoveRecordModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowModifyRecordModal(false)
        setShowRemoveRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }

    React.useEffect(() => {
        if(selectedAsset && menu?.daisyMenu) {
            getCryptoRecords(menu?.daisyMenu, selectedAsset?.id)
            .then((response) => {
                setRecords(response)
                
                setTotal({
                    quantity:  response.quantity,
                    value:  response.value
                })
            })
            .catch((error) => {
                console.error(error);
            });

            getCryptoDepositByGroupAndAsset(menu?.daisyMenu, selectedAsset?.id)
            .then((response) => {
                setCryptoDeposits(response)
            })
            .catch((error) => {
                console.error(error);
            }); 

            getAssetPurchaseData(menu?.daisyMenu?.group_id, selectedAsset?.id)
            .then((response) => {
                setAssetPurchases(response);
            })
            .catch((error) => {
                console.error(error);
            });
        }
        
    }, [menu?.daisyMenu, recodsStateChange, selectedAsset])

    const getDepositRegisterId = (rowData: Crypto) => {
        return cryptoDeposits.find((cryptoDeposit) => cryptoDeposit.transaction_id === rowData?.transaction_id)?.deposit_register_id;
    }

    const getDepositHolderData = (rowData: Crypto) => {
        if(!rowData) return;

        const cryptoDeposit = cryptoDeposits.find((cryptoDeposit) => cryptoDeposit.transaction_id === rowData?.transaction_id);

        const assetPData = assetPurchases.find((assetPurchase) => assetPurchase.depositRegisterId === cryptoDeposit?.deposit_register_id
        && assetPurchase.assetId === rowData?.assetType);

        if(!assetPData) return {
            message: "Az egész csoportra vonatkozik",
            color: ""
        }

        if(
            new Decimal(rowData.quantity).comparedTo(new Decimal(assetPData?.quantity)) === 0 &&
            new Decimal(rowData.amount).comparedTo(new Decimal(assetPData?.amount)) === 0 &&
            rowData.date?.toString().split('T')[0] === assetPData?.date?.toString().split('T')[0]
        ) {
            return {
                message: cryptoDeposit?.depositRegister?.user?.name + ' (' + cryptoDeposit?.depositRegister?.date?.toString().split('T')[0] + ')',
                color: 'bg-dark-brown',
            }
        } else {
            return { 
                message: 'Adategyeztetés sikertelen - ' + cryptoDeposit?.depositRegister?.user?.name + ' (' + cryptoDeposit?.depositRegister?.date?.toString().split('T')[0] + ')',
                color: 'bg-red-500'
            }

        }
    }

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    return (
        <>
        <div className="w-full overflow-x-auto text-white h-full">
            
           
            <AddNewRecordModal asset_id={selectedAsset?.id} title={"Adatsor hozzárendelése"} group={menu.daisyMenu} showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            <ModifyRecordModal row={selectedRecord} asset_id={selectedAsset?.id} title={"Adatsor módosítása"} group={menu.daisyMenu} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            <RemoveRecordModal row={selectedRecord} title={"Adatsor törlése"} group={menu.daisyMenu} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            
            <div className="text-center mt-5">
                <div className="flex items-center justify-center space-x-2">
                    <h1 className="text-2xl font-semibold text-dark-blue mb-4 mt-2">
                        Kiválasztva: <span className="text-brown">{selectedAsset?.name}</span>
                    </h1>
                    <img
                        src={`https://www.coinlore.com/img/${selectedAsset?.img}.webp`}
                        width={30}
                        height={30}
                        alt="Logo of asset"
                        className="object-contain mb-2"
                    />
                </div>
                <h1 className="text-xl font-semibold text-dark-blue mb-2">Coinok darabszáma: <span className="bg-gray-800 shadow-sm rounded-md text-white px-2 py-1">{total?.quantity}</span></h1>
                {/*<h1 className="text-xl font-semibold text-dark-blue mb-2">Likviditás: <span className="bg-gray-800 shadow-sm rounded-md text-white px-2 py-1">{total?.value}$</span></h1>*/}
                <button className="text-md text-center p-2 bg-success shadow-lg rounded mb-2 hover:opacity-80" onClick={() => openModal()}>Új adatsor rögzítése</button>
            </div>
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Darabszám</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Árfolyam USDT</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Összeg USDT</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Kapcsolat a befizetésnyilvántartóval</th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Kezelés</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Törlés</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {records?.rows?.map((record, index) => (
                            <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.transaction_id}</td>
                                <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>{record.operation ? "BUY" : "SELL"}</td>
                                <td className={dataRowStyling}>{record?.quantity}</td>
                                <td className={dataRowStyling}>{record?.exchange_rate}</td>
                                <td className={dataRowStyling}>{record?.amount}</td>
                                <td 
                                    onClick={() => {
                                        const value = getDepositRegisterId(record);
                                        if(value) {
                                            navigate(`/cp/${value}`)
                                        }                                      
                                    }}
                                    className={`${dataRowStyling} ${getDepositHolderData(record)?.color} hover:underline`}>{getDepositHolderData(record)?.message}</td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openModifyRecordModal(record)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Módosít</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openRemoveRecordModal(record)
                                    }} className="text-error hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}