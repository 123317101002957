// HPMMainPage.jsx

import TabHandler from '../../components/humandc/TabHandler';
import { useDispatch, useSelector } from 'react-redux';

import { TabValues } from '../../store/reducers/hpmNavSlice';
import { selectHpmTabState } from '../../store/reducers/hpmNavSlice';

import TableInv from '../../components/humandc/subcomponents/TableInv';
import TableGold from '../../components/humandc/subcomponents/TableGold';
import { useNavigate } from 'react-router';
import TableCrypto from '../../components/humandc/subcomponents/TableCrypto';
import Dashboard from '../../components/humandc/subcomponents/Dashboard';
import TradingHelperPanel from '../../components/humandc/subcomponents/TradingHelper';
import FormContainer from '../../components/humandc/subcomponents/tables/portfolio/FormContainer';
export default function HPMMainPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTab = useSelector(selectHpmTabState);

  const selectedTabView = () => {
    switch(selectedTab) {
      case TabValues.TAB_PORTFOLIO:
        return <FormContainer/>
      case TabValues.TAB_WITHDRAW:
        return null
      case TabValues.TAB_CRYPTO:
        return <TableCrypto/>
      case TabValues.TAB_GOLD:
        return <TableGold/>
      case TabValues.TAB_INV:
        return <TableInv/>
      case TabValues.TAB_TRADING_HELP:
        return <TradingHelperPanel/>
      case TabValues.DASHBOARD:
        return <Dashboard/>
    }
  }
  return (
    <div className="m-1">
      <TabHandler/>
      {selectedTabView()}
    </div>
  );
}