import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LeverageTrading } from "../../../../models/leveragetrading.model";
import { Asset } from "../../../../models/assets.model";
import { Group } from "../../../../models/group.model";
import { Assets } from "../../../../enums/assets";
import { getCoinExchangeRate } from "../../../../api/getCoinExchangeRate";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    row: LeverageTrading | undefined;
    handleStateChange: () => void;
};

export const AddLeverageTradingModal = ({ group, row, title, showModal, closeModal, handleStateChange }: ModalProps) => {

    const [currentPrice, setCurrentPrice] = useState<string>("");

    const [recordData, setRecordData] = useState<LeverageTrading>({
        group_id: -1,
        base: 0,
        multiplier: 1,
        open: 0,
        assetType: Assets.BTC, 
        exchange_rate: 0, 
        pl: 0
    });

    useEffect(() => {
        if(!recordData.assetType && (
            recordData.assetType === Assets.BTC || recordData.assetType === Assets.ETH
        )) return;

        getCoinExchangeRate(recordData?.assetType === Assets.BTC ? "90" : "80")
        .then((response: string) => {
            console.log(JSON.stringify(response))
          setCurrentPrice(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [recordData.assetType])

    useEffect(() => {
        if (!row || !group) return;

        setRecordData({
            group_id: row.group_id,
            base: row.base,
            multiplier: row.multiplier,
            open: row.open,
            assetType: row.assetType,
            exchange_rate: row.exchange_rate,
            pl: row.pl
        });
    }, [row, group]);

    const handleChange = (field: keyof LeverageTrading, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const inputValue = e.target.value;
  
      setRecordData(prevData => {
        const updatedRecordData = {
            ...prevData,
            [field]: field === 'multiplier' ? parseFloat(inputValue) : parseInt(inputValue, 10),
        };

        if (field === 'base' || field === 'multiplier') {
            updatedRecordData.open = updatedRecordData.base * updatedRecordData.multiplier;
        } else if (field === 'assetType') {
            updatedRecordData.assetType = parseInt(inputValue, 10);
        }

        return updatedRecordData;
      });
    };

    const updateRecord = async () => {
        if (!group || !row) return;

        //
    };

    

    return (
        <>
            {showModal && (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                    <h3 className="text-xl text-brown font-semibold">
                                        <span className="text-dark-blue">HUMAN D.C: Tőkeáttét - {title}</span>
                                    </h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form>

                                    <div className="flex p-2 rounded shadow-md bg-gray-200 mb-5">
                                        <div className="w-full text-center items-center">
                                            <label className="block text-sm font-bold mt-5 text-brown text-center justify-center">
                                                <span className="text-success">Eszköz Típusa</span>
                                            </label>
                                            <div className="flex justify-center mt-2">
                                                <label className="mr-4 text-lg">
                                                    <input
                                                        type="radio"
                                                        name="assetType"
                                                        value={1}
                                                        checked={recordData.assetType === Assets.BTC}
                                                        onChange={() => setRecordData({ ...recordData, assetType: Assets.BTC })}
                                                        className="mr-2 w-6 h-6"
                                                    />
                                                    Bitcoin
                                                </label>
                                                <label className="mr-4 text-lg">
                                                    <input
                                                        type="radio"
                                                        name="assetType"
                                                        value={2}
                                                        checked={recordData.assetType === Assets.ETH}
                                                        onChange={() => setRecordData({ ...recordData, assetType: Assets.ETH })}
                                                        className="mr-2 w-6 h-6"
                                                    />
                                                    Ethereum
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                        <div className="flex p-2 rounded shadow-md bg-gray-200 mb-5">
                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="base">
                                                    <span className="text-success">Alaptőke</span>
                                                </label>
                                                <input
                                                    value={recordData.base}
                                                    onChange={(e) => handleChange('base', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="base"
                                                    type="number"
                                                    placeholder="Alaptőke"
                                                />
                                            </div>

                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="multiplier">
                                                    <span className="text-success">Tőkeáttét</span>
                                                </label>
                                                <select
                                                    value={recordData.multiplier}
                                                    onChange={(e) => handleChange('multiplier', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="multiplier"
                                                >
                                                    {[1, 2, 3, 4, 5].map((value) => (
                                                        <option key={value} value={value}>{`${value}X`}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="open">
                                                    <span className="text-success">Tőkeáttétes pozíció</span>
                                                </label>
                                                <input
                                                    value={recordData.open}
                                                    onChange={(e) => handleChange('open', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="open"
                                                    type="number"
                                                    placeholder="Tökeáttétes pozíció"
                                                />
                                            </div>
                                            {/* jelenlegi árfolyam */}
                                            {/* P/L */}
                                        </div>

                                        <div className="flex p-2 rounded shadow-md bg-gray-200 mb-5">
                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="base">
                                                    <span className="text-success">Vételi árfolyam</span>
                                                </label>
                                                <input
                                                    value={recordData.exchange_rate}
                                                    onChange={(e) => handleChange('exchange_rate', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="exchange_rate"
                                                    type="number"
                                                    placeholder="Vételi árfolyam"
                                                />
                                            </div>

                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="multiplier">
                                                    <span className="text-success">Jelenlegi árfolyam</span>
                                                </label>
                                                <p className="text-xl py-1">{currentPrice}$</p>
                                            </div>

                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="open">
                                                    <span className="text-success">P/L</span>
                                                </label>
                                                <p>{recordData.pl}</p>
                                            </div>

                                            {/* vételi árfolyam */}
                                            {/* jelenlegi árfolyam */}
                                            {/* P/L */}
                                        </div>
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Mégse
                                    </button>
                                    <button
                                        className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={updateRecord}
                                    >
                                        Mentés
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
                </>
            )}
        </>
    );
};
