import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { Asset } from "../../../../models/assets.model";
import { Crypto } from "../../../../models/crypto.model";
import AssetBuyPanel from "./AssetBuyPanel";
import AssetSellPanel from "./AssetSellPanel";
import { getLatestRecordsForTradingHelper } from "../../../../api/getLatestRecordsForTradingHelper";
import { TradingHelper } from "../../../../models/tradinghelper.model";

interface AssetTradingSettingsProps {
  selectedAsset: Asset | undefined;
  tradingHelperData: TradingHelper[] | undefined;
}

const AssetTradingSettings: React.FC<AssetTradingSettingsProps> = ({ selectedAsset, tradingHelperData }) => {

  
  const [takenRecords, setTakenRecords] = useState<Crypto[]>([]);
  const [myTradingHelperData, setMyTradingHelperData] = useState<TradingHelper>();

  const [avgPrice, setAvgPrice] = useState<number>(0);

  const menu = useSelector(selectMenuStates);

  React.useEffect(() => {
    if(tradingHelperData) {
        const tradingHelper = tradingHelperData.find((trd) => trd.assetId === selectedAsset?.id);

        if(tradingHelper) {
            setAvgPrice(tradingHelper?.avg_transaction_rate)
            openRecords(tradingHelper?.openedRecords, tradingHelper?.operation, tradingHelper?.allRecords)
        }

        setMyTradingHelperData(tradingHelper);
        
    }
  }, [tradingHelperData])

  const openRecords = async (recordNumber: number, operation: number, allRecords: boolean) => {
    if(!menu.daisyMenu || !selectedAsset) return;

    try {

      const response = await getLatestRecordsForTradingHelper(menu.daisyMenu, selectedAsset?.id, recordNumber, operation, allRecords);
      setTakenRecords(response);
    } catch (error) {
      console.error(error);
    }
  };

  const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"

  return (
    <Fragment>
      {myTradingHelperData ?
        <div className="px-4">
          {takenRecords.length > 0 ?
          <div className="bg-white" style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
              <thead className="bg-gray-800 sticky top-0 text-white">
                <tr>
                  <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                  <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                  <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                  <th className="border p-2 hover:opacity-80 cursor-pointer">Darabszám</th>
                  <th className="border p-2 hover:opacity-80 cursor-pointer">Árfolyam USDT</th>
                  <th className="border p-2 hover:opacity-80 cursor-pointer">Összeg USDT</th>
                </tr>
              </thead>
              <tbody className="bg-dark-blue text-white">
                {takenRecords.map((record, index) => (
                  <tr key={index} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                    <td className={dataRowStyling}>{record.transaction_id}</td>
                    <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                    <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>
                      {record.operation ? "BUY" : "SELL"}
                    </td>
                    <td className={dataRowStyling}>{record.quantity}</td>
                    <td className={dataRowStyling}>{record.exchange_rate}</td>
                    <td className={dataRowStyling}>{record.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="bg-white flex justify-center items-center border-b-2 border-dark-blue">
              <p className="bg-gray-200 rounded px-2 py-1 mb-2 text-xl text-center">
                A tranzakciók átlagos árfolyama: <span className="text-emerald-900 font-semibold">{tradingHelperData ? avgPrice : 0}$</span>
              </p>
            </div>
          </div>
            : <p className="text-xl font-semibold text-center mb-2 mt-2">Még nincsenek lekérve tranzakciók.</p>
          }
      
          <div className="flex space-x-8 mt-5">
            <div className="w-1/2 px-2 py-2 rounded shadow-md shadow-gray-400">
              <AssetBuyPanel selectedAsset={selectedAsset} takenRecords={takenRecords} tradingHelperData={myTradingHelperData}/>
            </div>
            <div className="w-1/2 px-2 py-2 rounded shadow-md shadow-gray-400">
              <AssetSellPanel selectedAsset={selectedAsset} takenRecords={takenRecords} tradingHelperData={myTradingHelperData}/>
            </div>
          </div>
        </div>
      :
          <div className="p-4">
            <p className="text-2xl">Előbb kérlek nyisd meg az eszköz kereskedési-segédletét! <span className="font-semibold text-brown">({selectedAsset?.name})</span></p>
          </div>
      }
    </Fragment>
  );
};

export default AssetTradingSettings;