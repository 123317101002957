import React, { useState } from 'react';
interface SVGProps {
    rotation: boolean;
    color: string;
}

export const ArrowIcon = ({ rotation, color }: SVGProps) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <svg
        viewBox="0 0 16 16" 
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        className={`fill-current z-40 w-4 h-6 mr-2 ${rotation ? 'rotate-0' : 'rotate-180'}`}
    >
        <path d="M6 8L2 8L2 6L8 5.24536e-07L14 6L14 8L10 8L10 16L6 16L6 8Z" fill={color}/>
    </svg>
  );
};