import React, { useState } from 'react';
interface SVGProps {
    color: string;
}

export const ChangeIcon = ({ color }: SVGProps) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const iconColor = hovered ? '#E8D788' : "#FFF"; // Change 'red' to the desired hover color

  return (
    <svg fill={iconColor} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg">
        <path d="M11,2C7,2,4,3.7,4,6s3,4,7,4s7-1.7,7-4S15,2,11,2z"/>
        <path d="M11,12c-2.9,0-5.4-0.8-7-2.1V11c0,2.3,3,4,7,4s7-1.7,7-4V9.9C16.4,11.2,13.9,12,11,12z"/>
        <path d="M11,17c-2.9,0-5.4-0.8-7-2.1V16c0,2.3,3,4,7,4s7-1.7,7-4v-1.1C16.4,16.2,13.9,17,11,17z"/>
        <path d="M11,22c-2.9,0-5.4-0.8-7-2.1V21c0,2.3,3,4,7,4s7-1.7,7-4v-1.1C16.4,21.2,13.9,22,11,22z"/>
        <path d="M11,27c-2.9,0-5.4-0.8-7-2.1V26c0,2.3,3,4,7,4s7-1.7,7-4v-1.1C16.4,26.2,13.9,27,11,27z"/>
        <path d="M21,24c-0.3,0-0.7,0-1,0v3c0.3,0,0.7,0,1,0c4,0,7-1.7,7-4v-1.1C26.4,23.2,23.9,24,21,24z"/>
        <path d="M21,19c-0.3,0-0.7,0-1,0v3c0.3,0,0.7,0,1,0c4,0,7-1.7,7-4v-1.1C26.4,18.2,23.9,19,21,19z"/>
        <path d="M21,9c-0.3,0-0.7,0-1,0V17c0.3,0,0.7,0,1,0c4,0,7-1.7,7-4S25,9,21,9z"/>
    </svg>
  );
};
