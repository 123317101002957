import React, { useState } from 'react';
interface SVGProps {
    color: string;
}

export const TransferIcon = ({ color }: SVGProps) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const iconColor = hovered ? '#E8D788' : "#FFF"; // Change 'red' to the desired hover color

  return (


<svg fill={iconColor} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg">
<title>transfer</title>
<path d="M0 28v-8q0-0.832 0.576-1.408t1.44-0.608h8q0.8 0 1.408 0.608t0.576 1.408v8q0 0.832-0.576 1.408t-1.408 0.576h-8q-0.832 0-1.44-0.576t-0.576-1.408zM0 7.968q0.032-0.8 0.608-1.376l4-4q0.448-0.48 1.056-0.576t1.12 0.16 0.864 0.704 0.352 1.12v2.016h2.016v4h-2.016v1.984q0 0.672-0.352 1.152t-0.896 0.704-1.12 0.128-1.024-0.576l-4-4q-0.64-0.608-0.608-1.44zM4 25.984h4v-4h-4v4zM12 9.984v-4h2.016v4h-2.016zM14.016 25.984v-4h1.984v4h-1.984zM16 9.984v-4h2.016v4h-2.016zM18.016 25.984v-4h1.984v4h-1.984zM20 12v-8q0-0.832 0.576-1.408t1.44-0.608h8q0.8 0 1.408 0.608t0.576 1.408v8q0 0.832-0.576 1.408t-1.408 0.576h-8q-0.832 0-1.44-0.576t-0.576-1.408zM22.016 25.984v-4h1.984v-1.984q0-0.672 0.384-1.152t0.864-0.704 1.12-0.096 1.056 0.512l4 4q0.608 0.64 0.576 1.44t-0.576 1.408l-4 4q-0.48 0.448-1.088 0.544t-1.12-0.128-0.864-0.704-0.352-1.12v-2.016h-1.984zM24 9.984h4v-4h-4v4z"></path>
</svg>
  );
};
