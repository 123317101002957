import React from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import ModifyRecordModal from "../../../modals/dashboard/ModifyRecordModal";
import AddNewRecordModal from "../../../modals/dashboard/AddNewRecordModal";
import RemoveRecordModal from "../../../modals/dashboard/RemoveRecordModal";
import { getLiquidityTransfers } from "../../../../../api/getLiquidityTransfers";
import { HumanDashboard } from "../../../../../models/types/HumanDashboard";
import { LiqudityTransfer } from "../../../../../models/liqtrans.model";
import ModifyLiquidityModal from "../../../modals/dashboard/ModifyLiquidityModal";

enum ModalType {
    None = "NONE",
    NewRecord = "NEW_RECORD",
    ModifyRecord = "MODIFY_RECORD",
    RemoveRecord = "REMOVE_RECORD",
    ModifyLiquidity = "MODIFY_LIQUIDITY",
}
export default function FormContainer () {

    const [activeModal, setActiveModal] = React.useState<ModalType>(ModalType.None);

    const [records, setRecords] = React.useState<HumanDashboard>();
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)

    const [selectedRecord, setSelectedRecord] = React.useState<LiqudityTransfer>();

    const menu = useSelector(selectMenuStates)

    const handleStateChange = () => setRecordsStateChange(!recodsStateChange);

    const openModal = (modalType: ModalType, record?: LiqudityTransfer) => {
        setSelectedRecord(record); // Optional: Set the selected record if needed
        setActiveModal(modalType);
    };
    
    const closeModal = () => {
        setActiveModal(ModalType.None);
    };

    React.useEffect(() => {
        getLiquidityTransfers(menu?.daisyMenu)
        .then((response) => {
            setRecords(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, recodsStateChange])

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    const validValue = "#E8D788";
    const defaultValue = "#D3D3D3";

    const getColor = (number1: number, number2: number) => {
        if(Number(number1) === Number(number2)) {
            return "#aaa";
        
        }
        return "#E8D788";
    }
    return (
        <>
        <div className="w-full overflow-x-auto text-white h-full">
            
            <div className="flex flex-row space-x-4 items-center justify-center py-2">
                <button className="px-4 py-3 border bg-dark-blue shadow-md rounded hover:opacity-80 text-lg" onClick={() => openModal(ModalType.NewRecord)}>Tranzakció rögzítése</button>
            </div>
            
            {activeModal === ModalType.ModifyLiquidity && (
                <ModifyLiquidityModal 
                    title={"Likviditás értéke"} 
                    group={menu.daisyMenu} 
                    showModal={activeModal === ModalType.ModifyLiquidity} 
                    closeModal={closeModal} 
                    handleStateChange={handleStateChange} 
                />
            )}

            {activeModal === ModalType.ModifyRecord && (
                <ModifyRecordModal 
                    title={"Adatsor módosítása"} 
                    group={menu.daisyMenu} 
                    showModal={activeModal === ModalType.ModifyRecord} 
                    closeModal={closeModal} 
                    handleStateChange={handleStateChange} 
                    row={selectedRecord} 
                />
            )}

            {activeModal === ModalType.NewRecord && (
                <AddNewRecordModal 
                    title={"Adatsor hozzárendelése"} 
                    group={menu.daisyMenu} 
                    showModal={activeModal === ModalType.NewRecord} 
                    closeModal={closeModal} 
                    handleStateChange={handleStateChange} 
                />
            )}

            {activeModal === ModalType.RemoveRecord && (
                <RemoveRecordModal 
                    title={"Adatsor törlése"} 
                    group={menu.daisyMenu} 
                    showModal={activeModal === ModalType.RemoveRecord} 
                    closeModal={closeModal} 
                    handleStateChange={handleStateChange} 
                    row={selectedRecord}
                />
            )}

            
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum kezdés</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum végzés</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Megjegyzés</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Összeg (USD)</th>
                            
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Módosít</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Törlés</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {records?.transfers?.map((record, index) => (
                            <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.id}</td>
                                <td className={dataRowStyling}>{record.start_date.toString().split('T')[0]}</td>
                                <td className={dataRowStyling}>{record.end_date.toString().split('T')[0]}</td>
                                <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>{record.operation ? "ÉRKEZETT" : "ELVONVA"}</td>
                                <td className={dataRowStyling}>{record?.caption}</td>
                                <td className={dataRowStyling}>{record?.amount}</td>
                                
                                

                                <td className={dataRowStyling}>
                                    <p onClick={() => openModal(ModalType.ModifyRecord, record)} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Módosítás</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => openModal(ModalType.RemoveRecord, record)}  className="text-red-500 hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}