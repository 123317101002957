import React, { useEffect, useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/reducers/userSlice";
import { DepositRegister } from "../../../../models/depositregister";

type ModifiedPayload = Omit<DepositRegister, 'user' | 'group_id' | 'group' | 'invested_amount_alt'>;

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    row: DepositRegister | undefined;
}

const ModifyManualRecordModal = ({ group, row, title, showModal, closeModal, handleStateChange } : Omit<ModalProps, "user">) => {

    const user = useSelector(selectUser);

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        id: -1,
        date: new Date(),
        user_id: user.userInfo.user_id,
        invested_amount_eur: 0,
        net_invested_amount_eur: 0,
        invested_amount_usdt: 0,
        deduction_all: 0,
        deduction_physical_gold: 0,
        deduction_mining: 0,
        cost_deduction: 0,
        deduction_alt: 0,
        amount_in_trade: 0,
        liquidity: 0,
        liquidity_paxg: 0,
        fee: 0,
        capital_ratio: 0,
        bonus: 0,
        bonus_amount: 0,
    });
    
    React.useEffect(() => {
        if(!row) return;
        setRecordData({
            id: row.id,
            user_id: row.user_id,
            date: row.date,
            invested_amount_eur: row.invested_amount_eur,
            net_invested_amount_eur: row.net_invested_amount_eur,

            invested_amount_usdt: row.invested_amount_usdt,
            deduction_all: row.deduction_all,
            deduction_physical_gold: row.deduction_physical_gold,
            deduction_mining: row.deduction_mining,
            deduction_alt: row.deduction_alt,

            cost_deduction: row.cost_deduction, // new
            amount_in_trade: row.amount_in_trade, // new

            liquidity: row.liquidity,
            liquidity_paxg: row.liquidity_paxg,
            fee: row.fee,
            capital_ratio: row.capital_ratio,
            bonus: row.bonus,
            bonus_amount: row.bonus_amount

            })
    }, [row])

    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
    
        // Check if the input is a valid float with two decimals for rate_btc and rate_eth

        // For other types or invalid input, handle them as before
        setRecordData(prevData => ({
            ...prevData,
            [type]: (type === "date") ? inputValue : parseInt(inputValue, 10) || 0
        }));
    };


    const updateRecord = async () => {

        if(!group || !row) return;

        await responseHero.put(`${process.env.REACT_APP_API_URL}/humandc/manualdeposit/${row?.id}`, { depositRegistration: recordData 
        }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen módosítottad a rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title} | {recordData?.date?.toString().split('T')[0]} <span className="text-dark-blue">| Beutalt összeg: </span>{recordData?.invested_amount_eur} EUR</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/6 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="invested_amount_eur">
                                            <span className="text-success">Befektetett összeg </span> <br/>EUR
                                        </label>
                                        <input
                                            value={recordData?.invested_amount_eur}
                                            onChange={(e) => handleChange('invested_amount_eur', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="invested_amount_eur" type="number" placeholder="Befektetett összeg (EUR)"/>
                                    </div>
                                
                                    <div className="w-1/6 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="invested_amount_eur">
                                            <span className="text-success">Banki költségek után</span> <br/>EUR
                                        </label>
                                        <input
                                            value={recordData?.net_invested_amount_eur}
                                            onChange={(e) => handleChange('net_invested_amount_eur', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="net_invested_amount_eur" type="number" placeholder="Befektetett összeg levonásokkal (EUR)"/>
                                    </div>

                                    <div className="w-1/6 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="capital_ratio">
                                            <span className="text-success">Tőkeviszonyszám </span> <br/>EUR
                                        </label>
                                        <input
                                            value={recordData?.capital_ratio}
                                            onChange={(e) => handleChange('capital_ratio', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="capital_ratio" type="number" placeholder="Tőkeviszonyszám EUR-ban"/>
                                    </div>

                                    <div className="w-1/6 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bónusz </span> <br/>%
                                        </label>
                                        <input
                                            value={recordData?.bonus}
                                            onChange={(e) => handleChange('bonus', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Bónusz % -ban"/>
                                    </div>

                                    <div className="w-1/6 pr-2 text-center items-center">

                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="date">
                                            <span className="text-success">Dátum </span> <br/>Beutalás dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                    </div>

                                    <div className="w-1/6 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Befizetett összeg </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.invested_amount_usdt}
                                            onChange={(e) => handleChange('invested_amount_usdt', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="Befizetett összeg USDT-ben"/>
                                    </div>
                                </div>

                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/5 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="deduction_all">
                                            <span className="text-success">Elvonás összesen </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.deduction_all}
                                            onChange={(e) => handleChange('deduction_all', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction_all" type="number" placeholder="Elvonás összesen USDT-ben"/>
                                    </div>

                                    <div className="w-1/5 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="deduction_physical_gold">
                                            <span className="text-success">Elvonás aranyra </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.deduction_physical_gold}
                                            onChange={(e) => handleChange('deduction_physical_gold', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction_physical_gold" type="number" placeholder="Elvonás aranyra USDT-ben"/>
                                    </div>

                                    <div className="w-1/5 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="deduction_alt">
                                            <span className="text-success">Elvonás altcoinra </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.deduction_alt}
                                            onChange={(e) => handleChange('deduction_alt', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction_alt" type="number" placeholder="Elvonás altcoinra USDT-ben"/>
                                    </div>

                                    <div className="w-1/5 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="deduction_mining">
                                            <span className="text-success">Elvonás bányagépre </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.deduction_mining}
                                            onChange={(e) => handleChange('deduction_mining', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction_mining" type="number" placeholder="Elvonás bányagépköltségekre USDT-ben"/>
                                    </div>
                                    <div className="w-1/5 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="cost_deduction">
                                            <span className="text-success">Elvonás költségekre </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.cost_deduction}
                                            onChange={(e) => handleChange('cost_deduction', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="cost_deduction" type="number" placeholder="Elvonás a Human D.C költségeire USDT-ben"/>
                                    </div>
                                    </div>

                                    <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="amount_in_trade">
                                            <span className="text-success">Kerskedésbe került összeg </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.amount_in_trade}
                                            onChange={(e) => handleChange('amount_in_trade', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="amount_in_trade" type="number" placeholder="Kerskedésbe került összeg USDT-ben"/>
                                    </div>

                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="liquidity">
                                            <span className="text-success">Likviditás </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.liquidity}
                                            onChange={(e) => handleChange('liquidity', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="liquidity" type="number" placeholder="Likviditás USDT-ben"/>
                                    </div>

                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="liquidity_paxg">
                                            <span className="text-success">Likviditás </span> <br/>PAXG
                                        </label>
                                        <input
                                            value={recordData?.liquidity_paxg}
                                            onChange={(e) => handleChange('liquidity_paxg', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="liquidity_paxg" type="number" placeholder="Likviditás PAXG-ben"/>
                                    </div>

                                    <div className="w-1/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="fee">
                                            <span className="text-success">Költségek </span> <br/>Fee
                                        </label>
                                        <input
                                            value={recordData?.fee}
                                            onChange={(e) => handleChange('fee', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="fee" type="number" placeholder="Fee költségek USDT-ben"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={updateRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default ModifyManualRecordModal;