import React, { useEffect, useState } from "react";
import { ModalProps } from "../../../types/modalProps";
import { User } from "../../../models/user.model";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserState } from "../../../store/reducers/selectedUserSlice";

type ModifiedPayload = Omit<User, "user_id" |"last_logged" | "admin">

const ModifyUserModal = ({ title, user, showModal, closeModal, handleStateChange } : ModalProps) => {

    const [changePasswordVisibility, setChangePasswordVisibility] = React.useState<boolean>(false);
    const select = useSelector(selectUserState)

    const [newUserData, setNewUserData] = useState<ModifiedPayload>({
        name: '',
        email: '',
        phone: '',
        card_number: '',
        password: '',
        bank_name: '', 
        address: '',
        location: '',
        birthplace: '',
        birthdate: new Date(),
        mother_name: '',
        id_card: '',
        exchange: '',
        network: '',
        network_address: ''
      });
      
      useEffect(() => {
        if (user) {
          setNewUserData({
            name: user.name,
            email: user.email,
            phone: user.phone,
            card_number: user.card_number,
            password: user.password,
            bank_name: user.bank_name, 
            address: user.address,
            location: user.location,
            birthplace: user.birthplace,
            birthdate: user.birthdate,
            mother_name: user.mother_name,
            id_card: user.id_card,
            exchange: user.exchange,
            network: user.network,
            network_address: user.network_address
          });
        }
      }, [user]);

    const modifyUser = async (user: User | undefined) => {
        if(!user) return;

        // if(user.admin === true) return toast.warning("Jogtalan módosítási kísérlet!")

        await responseHero.put(`${process.env.REACT_APP_API_URL}/users/${user.user_id}`, { user: newUserData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen módosítottad ${user.name} adatait!`);
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error(`Sikertelen módosítás! Lehet, hogy az email cím már szerepel a rendszerben!`);
        })
    }

    const handleChange = (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUserData(prevData => ({
            ...prevData,
            [type]: e.target.value,
        }));
    };

    return (
    <>
        {showModal && (
            <>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-dark-blue font=semibold"><b>{user?.name}</b> {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <div className="flex p-4 rounded shadow-md">

                                <div className="w-1/3 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="username">
                                        Név
                                    </label>
                                        <input value={newUserData.name} onChange={(e) => handleChange('name', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Név"/>
                                    
                                    </div>
                                    <div className="w-1/3 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="email">
                                        Email
                                    </label>
                                        <input value={newUserData.email} onChange={(e) => handleChange('email', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email cím"/>
                                    </div>
                                    <div className="w-1/3 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="phone">
                                        Telefonszám
                                    </label>
                                        <input value={newUserData.phone} onChange={(e) => handleChange('phone', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Telefonszám"/>
                                    </div>
                            </div>
                            <div className="flex p-4 rounded shadow-md">

                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="card">
                                        Bankszámlaszám
                                    </label>
                                        <input value={newUserData.card_number} onChange={(e) => handleChange('card_number', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Bankszámlaszám"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="bank_name">
                                        Bank neve
                                    </label>
                                        <input value={newUserData.bank_name} onChange={(e) => handleChange('bank_name', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="bank_name" type="text" placeholder="Bank neve"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">

                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="birthplace">
                                        Születési hely
                                    </label>
                                    <input value={newUserData.birthplace} onChange={(e) => handleChange('birthplace', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="birthplace" type="text" placeholder="Születési hely"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="birthdate">
                                        Születési idő
                                    </label>
                                    <input value={newUserData.birthdate.toString().split('T')[0]} type="date" onChange={(e) => handleChange('birthdate', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="birthdate" placeholder="Születési idő"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="address">
                                Lakcím
                                </label>
                                <input value={newUserData.address} onChange={(e) => handleChange('address', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="address" type="text" placeholder="Lakcím"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="location">
                                Tartózkodási ország
                                </label>
                                <input value={newUserData.location} onChange={(e) => handleChange('location', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Tartózkodási ország"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="mother_name">
                                Anyja neve
                            </label>
                                <input type="text" value={newUserData.mother_name} onChange={(e) => handleChange('mother_name', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="mother_name" placeholder="Anyja neve"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="id_card">
                                Személyi igazolvány száma
                            </label>
                                <input type="text" value={newUserData.id_card} onChange={(e) => handleChange('id_card', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="id_card" placeholder="SZIG szám"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="address">
                                Kriptováltó neve
                                </label>
                                <input value={newUserData.exchange} onChange={(e) => handleChange('exchange', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="address" type="text" placeholder="Váltó neve"/>
                                </div>

                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="location">
                                Hálózat
                                </label>
                                <input value={newUserData.network} onChange={(e) => handleChange('network', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Hálózat neve"/>
                                </div>

                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="location">
                                Hálózati cím
                                </label>
                                <input value={newUserData.network_address} onChange={(e) => handleChange('network_address', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Hálózati cím"/>
                                </div>
                            </div>

                            <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="password">
                                Jelszó
                            </label>

                            <div className="relative w-full">
                                <div className="absolute inset-y-0 right-0 flex items-center px-2">
                                    <input autoComplete="disabled" onClick={() => setChangePasswordVisibility(!changePasswordVisibility)} className="hidden js-password-toggle" id="toggle" type="checkbox" />
                                    <label className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label" htmlFor="toggle">{changePasswordVisibility ? "elrejt" : "megtekint"}</label>
                                </div>

                                <input value={newUserData.password} onChange={(e) => handleChange('password', e)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type={changePasswordVisibility ? "text" : "password"} placeholder="Jelszó"/>
                            </div>
                            
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={() => modifyUser(user)}
                        >
                            Módosít
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default ModifyUserModal;