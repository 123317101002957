import { useState } from "react";
import { responseHero } from "../../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import React from "react";
import { MinedCoinsTrading } from "../../../../../models/minedcoinstrading.model";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    operation: boolean;
}

type ModifiedPayload = Pick<MinedCoinsTrading, 
"date" |
"operation" |
"quantity" |
"exchange_rate" |
"amount">

const AddNewRecordModal = ({ title, showModal, closeModal, handleStateChange, operation } : ModalProps) => {



    const [recordData, setRecordData] = useState<ModifiedPayload>({
        date: new Date(),
        operation: true,
        quantity: "",
        exchange_rate: "",
        amount: 0,
    });

    React.useEffect(() => {
        setRecordData((prevData) => ({
            ...prevData,
            operation: operation,
            }));
    }, [operation])

    /*
        BUY = true
        SELL = false
    */
    

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
        ) => {
        const inputValue = e.target.value;
        
        if (type === "exchange_rate" && /^(\d*\.)?\d{0,4}$/.test(inputValue)) {
            setRecordData((prevData) => ({
            ...prevData,
            [type]: inputValue,
            }));
        } else if (type === "quantity" && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
            setRecordData((prevData) => ({
            ...prevData,
            [type]: inputValue,
            }));
        } else {
            setRecordData((prevData) => ({
            ...prevData,
            [type]: type === "date" ? inputValue : parseInt(inputValue),
            }));
        }
        };
          

    const makeRecord = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/minedcoinstrade`, { minedCoinsTrading: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen hozzáadtál egy rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">

                                    <div className="w-1/3 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Rögzítés </span> <br/>Dátum
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Árfolyam </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.exchange_rate}
                                            onChange={(e) => handleChange('exchange_rate', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Árfolyam USDT-ben"/>
                                    </div>
                                    {operation ? (
                                        <div className="w-2/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Összeg </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.amount}
                                            onChange={(e) => handleChange('amount', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Összeg USDT-ben"/>
                                        </div>
                                    ) : (
                                        <div className="w-2/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Kriptoeszköz </span> <br/>Darabszám
                                        </label>
                                        <input
                                            value={recordData?.quantity}
                                            onChange={(e) => handleChange('quantity', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="text" placeholder="Kriptoeszköz darabszáma"/>
                                        </div>
                                    )}
                                    </div>
                                </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewRecordModal;