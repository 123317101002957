import { TradingHelper } from "../models/tradinghelper.model";
import { responseHero } from "../utils/axiosInterceptor";

export type TradingHelperPatch = Partial<TradingHelper>;

export const updateTradingHelperData = (group_id: number, assetId: number, tradingHelper: TradingHelperPatch): Promise<TradingHelper> => {
    return new Promise<TradingHelper>((resolve, reject) => {

        if(!group_id || !assetId) return;
        
        responseHero.patch(`${process.env.REACT_APP_API_URL}/tradinghelper/${group_id}/${assetId}`, { tradingHelper: tradingHelper }).then((response) => {
            resolve(response.data as TradingHelper);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};