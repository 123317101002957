import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectNavStates } from "../../store/reducers/navSlice";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { getCurrentLiquidityValue } from "../../api/getCurrentLiquidityValue";
import { AddLeverageTradingModal } from "../../components/humandc/subcomponents/leveragetrading/AddLeverageTradingModal";

export default function LeveragedTrading() {
    const nav = useSelector(selectNavStates);
    const menu = useSelector(selectMenuStates);
    const [groupLiquidity, setGroupLiquidity] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!menu.daisyMenu) return;
        getCurrentLiquidityValue(menu?.daisyMenu.group_id)
            .then((response) => {
                setGroupLiquidity(response.amount);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [menu?.daisyMenu]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="m-5 p-3">
            <p className="px-2 py-2 text-xl text-center">
                Jelenlegi likviditás:
                <span className="font-semibold">
                    {groupLiquidity?.toLocaleString('en-En').replace(',', ' ') || 0}$
                </span>
            </p>
            <div className="flex flex-row space-x-4 py-2 px-4 justify-center items-center">
                <button
                    className="bg-success text-white rounded hover:opacity-100 opacity-80 px-2 py-2"
                    onClick={openModal}
                >
                    Új pozíció nyitása
                </button>
            </div>

            {/* Modal component */}
            {isModalOpen && (
                <AddLeverageTradingModal
                    title="Új pozíció"
                    group={menu?.daisyMenu}
                    showModal={isModalOpen}
                    closeModal={closeModal}
                    row={undefined} // Assuming a new position has no initial data
                    handleStateChange={() => console.log("")}
                />
            )}
        </div>
    );
}
