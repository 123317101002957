import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../store/reducers/menuSlice";
import { getAssets } from "../../../api/getAssets";
import { Asset } from "../../../models/assets.model";
import AssetsMenu from "./tradinghelper/AssetsMenu";
import AssetTradingSettings from "./tradinghelper/AssetTradingSettings";
import { getTradingHelperDataByGroup } from "../../../api/getTradingHelperDataByGroup";
import { TradingHelper } from "../../../models/tradinghelper.model";

const TradingHelperPanel: React.FC = () => {
  const menu = useSelector(selectMenuStates);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();
  const [tradingHelperData, setTradingHelperData] = useState<TradingHelper[]>([]);
  const [stateChange, setStateChange] = useState<boolean>(false);

  // Fetch assets on component mount
  useEffect(() => {
    const loadAssets = async () => {
      try {
        const response = await getAssets();
        setAssets(response);
      } catch (error) {
        console.error(error);
      }
    };
    loadAssets();
  }, []);

  // Set default asset after assets are loaded
  useEffect(() => {
    if (assets.length > 0) {
      setSelectedAsset(assets[0]);
    }
  }, [assets]);

  // Fetch trading helper data when menu or state changes
  useEffect(() => {
    if (!menu?.daisyMenu) return;

    const loadTradingHelperData = async () => {
      try {
        if(!menu?.daisyMenu) return;
        const response = await getTradingHelperDataByGroup(menu?.daisyMenu?.group_id);
        setTradingHelperData(response);
      } catch (error) {
        console.error(error);
      }
    };

    loadTradingHelperData();
  }, [menu?.daisyMenu, stateChange, selectedAsset]);

  const handleSelectedAsset = (asset: Asset) => {
    setSelectedAsset(asset);
  }
  const handleStateChange = () => setStateChange(!stateChange);

  return (
    <div className="flex flex-row mt-2">
      <AssetsMenu
        handleStateChange={handleStateChange}
        group_id={menu?.daisyMenu?.group_id}
        tradingHelperData={tradingHelperData}
        assetsList={assets}
        selectedAsset={selectedAsset}
        handleSelectedAsset={handleSelectedAsset}
      />
      <AssetTradingSettings
        selectedAsset={selectedAsset}
        tradingHelperData={tradingHelperData}
      />
    </div>
  );
};

export default TradingHelperPanel;
