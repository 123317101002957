import React, { useState } from "react";
import { Group } from "../../models/group.model"
import AddNewRecordModal from "./modals/AddNewRecordModal"
import { GroupMemberListProps } from "../../types/groupMemberListProps";
import { Daisy } from "../../models/daisy.model";
import { getDaisyRecords } from "../../api/getDaisyRecords";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import RemoveRecordModal from "./modals/RemoveRecordModal";
import ModifyRecordModal from "./modals/ModifyRecordModal";
import ModifyAssignmentsModal from "./modals/ModifyAssignmentsModal";
import PayoutPercentagesModal from "./modals/PayoutPercentagesModal";
import { UserPercentage } from "../../models/types/UserPercentage";
import { getDaisyPayoutPercentages } from "../../api/getDaisyPayoutPercentages";
import { Assignments } from "../../types/assigments";
import { updateAssignmentsInPayoutModal } from "./functions/updateAssignmentsInPayoutModal";
import { User } from "../../models/user.model";
import GoToUserProfile from "../user/modals/GoToUserProfile";

export default function FormContainer ({ screenSize, users } : GroupMemberListProps) {

    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showModifyAssignmentsModal, setShowModifyAssignmentsModal] = React.useState<boolean>(false); // edit record assignment state
    const [showRemoveRecordModal, setShowRemoveRecordModal] = React.useState<boolean>(false); // remove record modal state
    const [showModifyRecordModal, setShowModifyRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [showPayoutModal, setShowPayoutModal] = React.useState<boolean>(false); // payout state modal state

    const [assignments, setAssignments] = React.useState<Assignments[]>([]);

    const [userPercentages, setUserPercentages] = React.useState<UserPercentage[]>([]);

    const [records, setRecords] = React.useState<Daisy[]>([])
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)
    const [selectedRecord, setSelectedRecord] = React.useState<Daisy>();

    const [showStalkerModal, setShowStalkerModal] = React.useState<boolean>(false); // go to stalker modal
    const [selectedUser, setSelectedUser] = React.useState<User>();

    const openStalkerModal = (user: User) => {
        setSelectedUser(user)
        setShowStalkerModal(true)
    }

    const closeStalkerModal = () => {
        setShowStalkerModal(false);
        setSelectedUser(undefined);
    };

    const menu = useSelector(selectMenuStates)
    const date = new Date().toISOString().split('T')[0];

    const openModal = () => {
        setShowNewRecordModal(true)
    };

    const openModifyRecordModal = () => {
        setShowModifyRecordModal(true)
    };

    const openRemoveRecordModal = () => {
        setShowRemoveRecordModal(true)
    };

    const openModifyAssignmentsModal = () => {
        setShowModifyAssignmentsModal(true)
    };

    const openPayoutModal = () => {
        setShowPayoutModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowRemoveRecordModal(false)
        setShowModifyRecordModal(false)
        setShowModifyAssignmentsModal(false)
        setShowPayoutModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }

    React.useEffect(() => {
        getDaisyPayoutPercentages(menu?.daisyMenu)
        .then((response) => {
            setUserPercentages(response)
            setSelectedUser(response[0].user);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, recodsStateChange])
    
    React.useEffect(() => {
        getDaisyRecords(menu?.daisyMenu)
        .then((response) => {
            setRecords(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, recodsStateChange])

    React.useEffect(() => {
        const updateAssignments = () => {
            const assignmentResults = records.filter((record) => record.assigned_to_customer_crypto > 0 || record.assigned_to_customer_forex > 0);
            updateAssignmentsInPayoutModal(assignmentResults).then((res) => {
                setAssignments(res);
            });
            
        }
        
        updateAssignments();
    }, [records]);

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    const validValue = "#39FF14";
    const validValue2 = "#FFD700";
    const defaultValue = "#D3D3D3";

    return (
        <>
        <AddNewRecordModal title="Új adatsor" group={menu.daisyMenu} showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <ModifyRecordModal title="Adatsor szerkesztése" group={menu.daisyMenu} row={selectedRecord} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <RemoveRecordModal title="Adatsor törlése" group={menu.daisyMenu} row={selectedRecord} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <ModifyAssignmentsModal assignments={assignments} title="Kifizetések kezelése" group={menu.daisyMenu} row={selectedRecord} showModal={showModifyAssignmentsModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <PayoutPercentagesModal group={menu.daisyMenu} userPercentages={userPercentages} title={"Kifizetés eloszlása a csoportban"} users={users} showModal={showPayoutModal} closeModal={closeModal}/>
        <GoToUserProfile title="Átirányítás" user={selectedUser} showModal={showStalkerModal} closeModal={closeStalkerModal}/>

        <div className="w-full overflow-x-auto text-white h-full">
            <div className="text-center mt-5">
                <button className="text-md text-center p-2 bg-success shadow-lg rounded mb-2 hover:opacity-80" onClick={openModal}>Új adatsor rögzítése</button>
                <button className="text-md ml-2 text-center p-2 bg-green-600 shadow-lg rounded mb-2 hover:opacity-80" onClick={openModifyAssignmentsModal}>Már rögzített kiutalások</button>
                <button className="text-md ml-2 text-center p-2 bg-green-800 shadow-lg rounded mb-2 hover:opacity-80" onClick={openPayoutModal} >Összes kifizetés</button>
                
                {(users.length === 1) && (
                    <button className="text-md ml-2 text-center p-2 bg-indigo-600 shadow-lg rounded mb-2 hover:opacity-80" onClick={() => openStalkerModal(users[0])}>Kimutatás</button>
                )}
            </div>
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                <thead className="bg-gray-800 sticky top-0">
                    <tr>
                        <th className="border p-2 hover:opacity-80 cursor-pointer" rowSpan={2}>Dátum</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">Befizetés Crypto.com</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer" rowSpan={2}>Összes befizetés</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer" colSpan={2}>Ki és Befizetés a két kereskedésbe</th>

                        {/* Updates */}
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer" colSpan={2}>Fejlesztésre elvont összeg</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer" colSpan={2}>Kereskedésbe kerülő összeg</th>
                        {/* End */}

                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer" colSpan={2}>Kereskedési tőke kimutatás</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer" rowSpan={2}>Össz kereskedési tőke</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer" colSpan={4}>Részvény darabszám kimutatás</th>

                        {/*
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown" colSpan={5}>Osztalék kimutatás</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown" colSpan={3}>Eladott részvény után történt kifizetés</th>
                        */}
                        
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-success" rowSpan={2}>Kikérhető Crypto</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-success" rowSpan={2}>Kikérhető Forex</th>

                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown" colSpan={3}>Ügyfél része</th>

                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-cream text-dark-blue" rowSpan={2}>Összes kiutalt kifizetés</th>

                        <th className="border p-2 hover:opacity-80 cursor-pointer" rowSpan={2}>Nyereség 100% felett</th>
                        <th className="border p-2 bg-success hover:opacity-80 cursor-pointer" colSpan={2}>Nyereség elosztása</th>

                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown"  rowSpan={2} >Daisy tokenek száma</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer" rowSpan={2}>Műveletek</th>
                    </tr>
                    <tr>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">USD</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">CRYPTO</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">FOREX</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">CRYPTO</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">FOREX</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">CRYPTO</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">FOREX</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">CRYPTO</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">FOREX</th>

                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">Eladott db I.</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">Darabszám I. részvény</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">Árfolyam I. részvény</th>
                        <th className="border p-2 bg-dark-brown hover:opacity-80 cursor-pointer">Érték I.</th>

                        { /* 
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">Eladott db II.</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">Darabszám II. részvény</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">Árfolyam II. részvény</th>
                        <th className="border p-2 bg-brown hover:opacity-80 cursor-pointer">Érték II.</th>

                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-dark-brown">I. Részvény osztalék/db</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">II. Részvény osztalék/db</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-dark-brown">I. Részvény csomag után</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">II. Részvény csomag után</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-dark-blue">Összesen</th>

                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-dark-brown">I. Részvény csomag után</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">II. Részvény csomag után</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown bg-dark-blue">Összes kifizetés</th>

                        */ }

                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">CRYPTO</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-brown">FOREX</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer bg-dark-blue">Ügyfél része összesen</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer">Ügyfél része</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer">Cég része</th>
                    </tr>
                </thead>
                <tbody className="bg-dark-blue text-white">
                
                    {records.map((record, index) => (
                        <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                            <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                            <td className={dataRowStyling} style={{ color: record.deposit === 0 ? defaultValue : validValue}}>{record.deposit}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_deposit === 0 ? defaultValue : validValue}}>{record.sum_deposit}</td>
                            <td className={dataRowStyling} style={{ color: record.deposit_to_crypto === 0 ? defaultValue : validValue}}>{record.deposit_to_crypto}</td>
                            <td className={dataRowStyling} style={{ color: record.deposit_to_forex === 0 ? defaultValue : validValue}}>{record.deposit_to_forex}</td>

                            <td className={dataRowStyling} style={{ color: record.update_to_crypto === 0 ? defaultValue : validValue}}>{record.update_to_crypto}</td>
                            <td className={dataRowStyling} style={{ color: record.update_to_forex === 0 ? defaultValue : validValue}}>{record.update_to_forex}</td>
                            <td className={dataRowStyling} style={{ color: record.in_trade_crypto === 0 ? defaultValue : validValue}}>{record.in_trade_crypto}</td>
                            <td className={dataRowStyling} style={{ color: record.in_trade_forex === 0 ? defaultValue : validValue}}>{record.in_trade_forex}</td>

                            <td className={dataRowStyling} style={{ color: record.trading_capital_statement_crypto === 0 ? defaultValue : validValue}}>{record.trading_capital_statement_crypto}</td>
                            <td className={dataRowStyling} style={{ color: record.trading_capital_statement_forex === 0 ? defaultValue : validValue}}>{record.trading_capital_statement_forex}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_trading_capital_statement === 0 ? defaultValue : validValue}}>{record.sum_trading_capital_statement}</td>

                            { /* RÉSZVÉNY I. */}
                            <td className={dataRowStyling} style={{ color: record.share_amount === 0 ? defaultValue : validValue}}>{record.share_amount}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_share_amount === 0 ? defaultValue : validValue}}>{record.sum_share_amount}</td>
                            <td className={dataRowStyling} style={{ color: record.share_exchange_rate === 0 ? defaultValue : validValue}}>{record.share_exchange_rate}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_share_value === 0 ? defaultValue : validValue}}>{record.sum_share_value}</td>


                            { /* RÉSZVÉNY II.
                            <td className={dataRowStyling} style={{ color: record.share_amount_2 === 0 ? defaultValue : validValue}}>{record.share_amount_2}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_share_amount_2 === 0 ? defaultValue : validValue}}>{record.sum_share_amount_2}</td>
                            <td className={dataRowStyling} style={{ color: record.share_exchange_rate_2 === 0 ? defaultValue : validValue}}>{record.share_exchange_rate_2}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_share_value_2 === 0 ? defaultValue : validValue}}>{record.sum_share_value_2}</td>

                            */}

                            { /* OSZTALÉKOZGATÁS 
                            <td className={dataRowStyling} style={{ color: record.share_dividend_per_amount === 0 ? defaultValue : validValue}}>{record.share_dividend_per_amount}</td>
                            <td className={dataRowStyling} style={{ color: record.share_dividend_per_amount_2 === 0 ? defaultValue : validValue}}>{record.share_dividend_per_amount_2}</td>
                            <td className={dataRowStyling} style={{ color: record.share_dividend_after_kit === 0 ? defaultValue : validValue}}>{record.share_dividend_after_kit}</td>
                            <td className={dataRowStyling} style={{ color: record.share_dividend_after_kit2 === 0 ? defaultValue : validValue}}>{record.share_dividend_after_kit2}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_dividend === 0 ? defaultValue : validValue}}>{record.sum_dividend}</td>
                            */}

                            { /* ELADOTT RÉSZVÉNYEZGETÉS 
                            <td className={dataRowStyling} style={{ color: record.share_after_kit === 0 ? defaultValue : validValue}}>{record.share_after_kit}</td>
                            <td className={dataRowStyling} style={{ color: record.share_after_kit2 === 0 ? defaultValue : validValue}}>{record.share_after_kit2}</td>
                            <td className={dataRowStyling} style={{ color: record.sum_share_after_kit === 0 ? defaultValue : validValue}}>{record.sum_share_after_kit}</td>
                            */}
                            
                            <td className={dataRowStyling} style={{ color: record.requestable_crypto === 0 ? defaultValue : validValue}}>{record.requestable_crypto}</td>
                            <td className={dataRowStyling} style={{ color: record.requestable_forex === 0 ? defaultValue : validValue}}>{record.requestable_forex}</td>

                            <td className={dataRowStyling} style={{ color: record.assigned_to_customer_crypto === 0 ? defaultValue : validValue}}>{record.assigned_to_customer_crypto}</td>
                            <td className={dataRowStyling} style={{ color: record.assigned_to_customer_forex === 0 ? defaultValue : validValue}}>{record.assigned_to_customer_forex}</td>
                            <td className={dataRowStyling} style={{ color: record.total_allocation === 0 ? defaultValue : validValue}}>{record.total_allocation}</td>
                            
                            { /** AUTOMATA ÖSSZ KIUTALÁS OSZTALÉKKAL MINDENNEL */}
                            <td className={dataRowStyling} style={{ color: record.sum_total_allocation === 0 ? defaultValue : validValue}}>{record.sum_total_allocation}</td>

                            <td style={{ color: record.profit_over_100 === 0 ? defaultValue : validValue2}} className={dataRowStyling}>{record.profit_over_100 !== 0 ? record.profit_over_100 : 'N/A'}</td>
                            <td style={{ color: record.profit_customer === 0 ? defaultValue : validValue2}} className={dataRowStyling}>{record.profit_customer !== 0 ? record.profit_customer : 'N/A'}</td>
                            <td style={{ color: record.profit_company === 0 ? defaultValue : validValue2}} className={dataRowStyling}>{record.profit_company !== 0 ? record.profit_company : 'N/A'}</td>

                            <td className={dataRowStyling} style={{ color: record.daisy_token === 0 ? defaultValue : validValue}}>{record.daisy_token}</td>
                            <td>
                                <p onClick={() => {
                                    setSelectedRecord(record)
                                    openModifyRecordModal()
                                }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Módosít</p>
                                {(records.length - 1 === index) && (
                                    <p onClick={() => {
                                        setSelectedRecord(record)
                                        openRemoveRecordModal()
                                    }} className="text-error hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Visszavonás</p>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
        </>
    )
}