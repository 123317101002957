import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssets } from '../../../../../api/getAssets';
import { Asset } from '../../../../../models/assets.model';
import { responseHero } from '../../../../../utils/axiosInterceptor';
import { toast } from 'react-toastify';
import { getDepositRecordById } from '../../../../../api/getHumanDCFunctions';
import { DepositRegister } from '../../../../../models/depositregister';
import { selectMenuStates } from '../../../../../store/reducers/menuSlice';
import { useSelector } from 'react-redux';
import { Systems } from '../../../../../enums/systems';

export type ExtendedDepositRegister = {
    depositRegister: DepositRegister;
    assetsInTrade: Asset[];
};
type ExtendedAssets = {
    hasEntry: boolean;
    asset: Asset;
};
type ModifiedRecordData = {
    id?: number;
    date?: Date;
    quantity?: string;
    exchange_rate?: string;
    amount?: number;
};

export default function PurchaseCoinsPanel() {
    const { id } = useParams();
    const navigate = useNavigate();

    const undefinedRecordDataState = {
        date: new Date(),
        quantity: "0",
        exchange_rate: "0",
        amount: 0
    };

    const menu = useSelector(selectMenuStates);

    const [assets, setAssets] = React.useState<ExtendedAssets[]>([]);
    const [selectedAsset, setSelectedAsset] = React.useState<Asset | undefined>();

    const [recordData, setRecordData] = React.useState<ModifiedRecordData>(undefinedRecordDataState);

    const [newEntry, setNewEntry] = React.useState<boolean>(true); 
    const [stateChange, setStateChange] = React.useState(false);

    const [loadAssets, setLoadAssets] = React.useState(false);

    const [myDeposit, setMyDeposit] = React.useState<ExtendedDepositRegister>();
    
    const getAssetPurchaseData = async (id: string | undefined, selectedAssetId: number | null) => {
        if (!id || !selectedAssetId || !myDeposit) return;
    
        try {
            const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/assetp/${parseInt(id, 10)}/${selectedAssetId}`);
            
            if (response.data) {
                setNewEntry(false);
                setRecordData({
                    id: response.data.id,
                    date: new Date(response.data.date),
                    quantity: response.data.quantity,
                    exchange_rate: response.data.exchange_rate,
                    amount: response.data.amount,
                });
            } else {
                setNewEntry(true);
                setRecordData({
                    date: new Date(),
                    quantity: "0",
                    exchange_rate: "0",
                    amount: Math.round(
                        calculateAmount((myDeposit?.depositRegister?.amount_in_trade - myDeposit?.depositRegister?.liquidity), myDeposit?.depositRegister?.deduction_alt)
                    ),
                });
            }
        } catch (error: any) {
            setNewEntry(true);
            console.error(error.response?.data || error);
        }
    };

    const calculateAmount = (amountForDistribution: number, deductionAlt: number) => {
        if (!myDeposit || !selectedAsset) return 0;
    
        const assetsInTrade = myDeposit.assetsInTrade;
        const assetIds = assetsInTrade.map(asset => asset.id);
        const altAssetIds = assetsInTrade.filter(asset => asset.alt).map(asset => asset.id);
    
        const isActualAsset = assetIds.includes(selectedAsset.id);
    
        const depositGroupSystemId = myDeposit.depositRegister?.group?.system.id;
    
        if (depositGroupSystemId === Systems.HPM) { // Human D.C
            if (!selectedAsset.alt) {
                return amountForDistribution / 2;
            }
        }
    
        if (depositGroupSystemId === Systems.ALT) { // Human D.C Alt
            if (selectedAsset.alt) {
                return isActualAsset ? (amountForDistribution / altAssetIds.length) : 0;
            }
        }
    
        return 0; // Default return value
    }    
    
    React.useEffect(() => {
        if(!id) return;

        getDepositRecordById(parseInt(id, 10))
        .then((response) => {
            setMyDeposit(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [id])

    React.useEffect(() => {
        const fetchData = async () => {
            let temporaryStoredAssets: Asset[] = [];
            try {
                const response = await getAssets();
                temporaryStoredAssets = response;
            } catch (error) {
                console.error(error);
            }

            if (temporaryStoredAssets.length === 0) return;
    
            let temporaryStoredExtendedAssets: ExtendedAssets[] = [];

            for (const asset of temporaryStoredAssets) {
                try {
                    //const result = await getAssetHasEntry(id, asset?.id);
                    //console.log(result);

                    temporaryStoredExtendedAssets.push({
                        hasEntry: false,
                        asset: asset,
                    });
                } catch (error) {
                    console.error(error);
                }
            }
            setLoadAssets(false);
            setAssets(temporaryStoredExtendedAssets);
        };

        fetchData();

    }, [stateChange]);
    

    React.useEffect(() => {

        const fetchData = async () => {
            if(!selectedAsset) return;

            await getAssetPurchaseData(id, selectedAsset?.id);
        }

        setRecordData(undefinedRecordDataState);
        fetchData();
    }, [id, selectedAsset, myDeposit])

    const handleChange = (type: keyof ModifiedRecordData, e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if(type === "date") {
            setRecordData(prevData => ({
                ...prevData,
                [type]: new Date(inputValue)
            }));
        } else if(type === "quantity" && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
            setRecordData(prevData => ({
                ...prevData,
                [type]: inputValue
            }));
        } else if(type === "exchange_rate" && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
            
            setRecordData(prevData => ({
                ...prevData,
                [type]: inputValue
            }));

            const value = ((recordData.amount ? recordData.amount / parseFloat(inputValue) : 0));

            setRecordData(prevData => ({
                ...prevData,
                quantity: selectedAsset?.alt ? value.toFixed(2) : value.toFixed(8) 
            }))

        } else if(type === "amount") {
            setRecordData(prevData => ({
                ...prevData,
                [type]: parseInt(inputValue, 10)
            })); 
        }
    }

    const submit = async () => {
        if(!id || !selectedAsset || !menu?.daisyMenu) return toast.warn("Kérlek válassz ki egy eszközt először! ");

        try {
            const response = await responseHero.post(`${process.env.REACT_APP_API_URL}/assetp/${menu?.daisyMenu?.group_id}`, {
                record: {
                    ...recordData,
                    depositRegisterId: parseInt(id, 10),
                    assetId: selectedAsset?.id
                }
            });
            console.log(response.data);
            handleStateChange();
            toast.success("Sikeres adatrögzítés!")
        } catch (error: any) {
            toast.error("Sikertelen adatrögzítés!")
            console.error(error.response?.data || error.message); // Hibakezelés
        }
    };

    const modify = async () => {
        if(!recordData?.id || !id || !selectedAsset) return toast.warn("Kérlek válassz ki egy eszközt először! ");

        try {
            const response = await responseHero.put(`${process.env.REACT_APP_API_URL}/assetp/${recordData?.id}`, {
                record: recordData
            });
            console.log(response.data);
            handleStateChange();
            toast.success("Sikeres adatmódosítás!")
        } catch (error: any) {
            toast.error("Sikertelen adatmódosítás!")
            console.error(error.response?.data || error.message); // Hibakezelés
        }
    };

    const handleStateChange = () => {
        setStateChange(!stateChange);
    }
    


    return (
        <div className="min-h-screen flex flex-col p-4">
            {/* Assetek sávja */}
            <div className="bg-gray-800 shadow-md rounded-lg p-4 mb-6">
                <h2 className="text-xl font-semibold text-white mb-2">Elérhető eszközök: <span className='text-gray-400'>{loadAssets && "Eszközök betöltése..."}</span></h2>
                <div className="flex overflow-x-auto space-x-4 py-2">
                    {assets.map((asset) => (
                        <div
                            key={asset.asset.id}
                            onClick={() => setSelectedAsset(asset.asset)}
                            className={`flex flex-col items-center border border-gray-600 rounded-lg p-4 w-48 flex-shrink-0 transition-transform transform cursor-pointer ${
                                selectedAsset?.id === asset.asset.id ? 'bg-gray-600' : 'bg-gray-700'
                            } hover:scale-105 hover:shadow-xl hover:bg-gray-600`}
                        >
                            <img
                                src={`https://www.coinlore.com/img/${asset.asset?.img}.webp`}
                                alt={asset.asset.name}
                                className="w-12 h-12 mb-2"
                            />
                            <p className="text-white text-sm font-semibold mb-1">{asset.asset.name}</p>
                            {/*<p className={`${asset.hasEntry ? 'text-emerald-400 font-semibold' : 'text-red-400'} text-xs`}>{ asset.hasEntry ? "Rögzítve" : "Még nincs rögzítve" }</p>*/}
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-gray-800 shadow-lg rounded-lg p-6 flex flex-row">
                <div className='w-1/2'>
                    <div className="bg-gray-700 border border-gray-600 rounded-lg p-4 mb-6">
                    <div className='flex flex-row justify-center items-center mb-10 space-x-4'>
                        <div className="flex items-center justify-center">
                            <h2 className="text-2xl font-semibold text-gray-300">Kiválasztva: </h2>
                        </div>
                        <div className="flex items-center justify-center">
                            {selectedAsset ? (
                            <div className="flex items-center">
                                {assets.find(asset => asset.asset.id === selectedAsset.id) ? (
                                <>
                                    <p className="text-white text-lg">
                                        {assets.find(asset => asset.asset.id === selectedAsset.id)?.asset?.name}
                                    </p>

                                    <img
                                    src={`https://www.coinlore.com/img/${assets.find(asset => asset.asset.id === selectedAsset?.id)?.asset?.img.toLowerCase()}.webp`}
                                    alt={assets.find(asset => asset.asset.id === selectedAsset?.id)?.asset?.name}
                                    className="w-12 h-12 ml-4"
                                    />
                                    
                                </>
                                ) : (
                                <p className="text-gray-400">Nincs kiválasztott eszköz.</p>
                                )}
                            </div>
                            ) : (
                            <p className="text-gray-400">Nincs kiválasztott eszköz.</p>
                            )}
                        </div>
                        </div>

                        <form className="space-y-4">
                            <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4">
                                <div className="flex-1">
                                    <label htmlFor="date" className="block text-gray-300 font-semibold mb-1">Dátum</label>
                                    <input
                                        onChange={(e) => handleChange("date", e)}
                                        value={recordData?.date?.toISOString().split('T')[0]}
                                        type="date"
                                        id="date"
                                        className="w-full p-2 bg-gray-800 border border-gray-600 rounded-lg text-gray-300 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        required
                                    />
                                </div>
                                <div className="flex-1">
                                    <label htmlFor="quantity" className="block text-gray-300 font-semibold mb-1">Vásárolt mennyiség</label>
                                    <input
                                        onChange={(e) => handleChange("quantity", e)}
                                        value={recordData?.quantity}
                                        type="text"
                                        id="quantity"
                                        pattern="^\d*\.?\d+$"
                                        className="w-full p-2 bg-gray-800 border border-gray-600 rounded-lg text-gray-300 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        placeholder="Pl. 0.7374234"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4">
                                <div className="flex-1">
                                    <label htmlFor="rate" className="block text-gray-300 font-semibold mb-1">Árfolyam</label>
                                    <input
                                        onChange={(e) => handleChange("exchange_rate", e)}
                                        value={recordData?.exchange_rate}
                                        type="number"
                                        id="rate"
                                        step="0.01"
                                        className="w-full p-2 bg-gray-800 border border-gray-600 rounded-lg text-gray-300 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        placeholder="Pl. 12345.12"
                                        required
                                    />
                                </div>
                                <div className="flex-1">
                                    <label htmlFor="amount" className="block text-gray-300 font-semibold mb-1">Összeg</label>
                                    <input
                                        onChange={(e) => handleChange("amount", e)}
                                        value={recordData?.amount}
                                        type="number"
                                        id="amount"
                                        className="w-full p-2 bg-gray-800 border border-gray-600 rounded-lg text-gray-300 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        placeholder="Pl. 20000"
                                        required
                                    />
                                </div>
                            </div>
                        </form>
                        <button
                                className="mt-5 bg-indigo-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
                                onClick={newEntry ? submit : modify}
                            >
                            
                            {newEntry ? 'Vásárlás' : 'Módosítás'}
                        </button>
                    </div>
                </div>

                <div className='w-1/2'>
                    <p className='text-2xl text-center text-gray-400 font-semibold mb-2'>A befizetés részletei</p>
                    <p className='text-2xl text-center text-gray-500'>Csoport: <span className='text-white'>{menu?.daisyMenu?.group_name}</span></p>
                    <p className='text-2xl text-center text-gray-500'>Befizető: <span className='text-white'>{myDeposit?.depositRegister?.user?.name}</span></p>
                    <p className='text-2xl text-center text-gray-500'>Dátum: <span className='text-white'>{myDeposit?.depositRegister?.date?.toString().split('T')[0]}</span></p>
                </div>

            </div>
            
        </div>
    );
}
