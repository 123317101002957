import React from "react";
import { responseHero } from "../utils/axiosInterceptor";
import { Group } from "../models/group.model";
import { useSelector, useDispatch } from "react-redux";
import { selectMenuStates, set_daisy_menu } from "../store/reducers/menuSlice";

type GroupsSubMenuProps = {
    system_id: number;
    system_id2?: number;
    connectionsFlag?: boolean;
};

export default function GroupsSubMenu({ system_id, system_id2, connectionsFlag }: GroupsSubMenuProps) {
    const [groups, setGroups] = React.useState<Group[]>([]);
    const dispatch = useDispatch();
    const menu = useSelector(selectMenuStates);

    /* Effects */
    React.useEffect(() => {
        // Fetch groups only if not already loaded
        if (groups.length === 0) {
            const getGroups = async () => {
                try {
                    const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/groups/${connectionsFlag}`);
                    const filteredGroups = response.data.filter(
                        (group: Group) => 
                            (group.system_id === system_id || group.system_id === system_id2 || group.connected) && group.running === true
                    );
                    if (filteredGroups.length > 0) {
                        setGroups(filteredGroups);
                    }
                } catch (error: any) {
                    console.error(error.response?.data);
                }
            };
            getGroups();
        }
    }, [system_id, system_id2, connectionsFlag, groups.length]);

    React.useEffect(() => {
        const foundGroupInThisProject = groups.find((group) => group.group_id === menu.daisyMenu?.group_id);
        if (groups.length > 0 && (!menu.daisyMenu || !foundGroupInThisProject)) {
            dispatch(set_daisy_menu(groups[0]));
        }
    }, [groups, menu.daisyMenu, dispatch]);

    // Render component only if there are groups to display
    if (groups.length <= 0) {
        return null;
    }

    return (
        <nav className="bg-dark-blue border-t-2 border-brown pt-1">
            <div className="overflow-x-auto px-4 py-3 max-w-screen-xl mx-auto">
                <ul className="flex flex-nowrap font-medium mt-0 space-x-8 text-md">
                    {groups.map((group) => (
                        <li key={group.group_id} className="flex mb-2">
                            <button
                                onClick={() => dispatch(set_daisy_menu(group))}
                                className={`hover:text-cream ml-4 ${
                                    menu.daisyMenu?.group_name === group.group_name ? "text-cream" : "text-white"
                                }`}
                            >
                                {group.group_name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
}
