import { MinedCoinsTransfer } from "../models/minedcoinstransfer.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getMinedCoinsTransfers = (miningMachineId: number) : Promise<MinedCoinsTransfer[]> => {
    return new Promise<MinedCoinsTransfer[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/minedcoins/${miningMachineId}`).then((response) => {
            resolve(response.data as MinedCoinsTransfer[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};