import React, { Fragment } from 'react';
import { Spinner } from '../../utils/Spinner';
import { GroupMemberListProps } from '../../types/groupMemberListProps';
import { selectMenuStates } from '../../store/reducers/menuSlice';
import { useSelector } from 'react-redux';
import { User } from '../../models/user.model';
import GoToUserProfile from '../user/modals/GoToUserProfile';


export default function UserList ({ screenSize, users } : GroupMemberListProps) {

    
    const [spinner, setSpinner] = React.useState<boolean>(false)

    const [selectedUser, setSelectedUser] = React.useState<User>();
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const menu = useSelector(selectMenuStates)

    const closeModal = () => {
        setSelectedUser(undefined);
        setShowModal(false);
    }
    
    const openModal = (user: User) => {
        setSelectedUser(user);
        setShowModal(true);
    }

    const handleStateChange = () => {};

    return (
        <>
        <GoToUserProfile title="Átirányítás" user={selectedUser} showModal={showModal} closeModal={closeModal}/>
        <Spinner show={spinner} width={12} height={12}/>
        {(menu.daisyMenu && !spinner) && (
            <>
            {users && ( 
                <div>
                    {users.length === 0 ? <p className="mt-2 text-xl">Nincs felhasználó a csoportban</p> 
                        :
                        
                        <div style={{ maxHeight: screenSize?.dynamicHeight / 2, overflowY: 'auto', overflowX: 'auto' }}>
                            <table className="divide-y overflow-x table-fixed divide-gray-600 shadow-lg">
                                <thead className="bg-dark-blue">
                                    <tr>
                                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Sorszám</th>
                                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Név</th>
                                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Befizetett</th>
                                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Százalék</th>
                                    {users[0].miningData && (
                                        <>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Összesen termelt coin</th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Átváltott coinmennyiség</th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Átváltott coinok értéke</th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Energiaköltségek</th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Birtokolt coinmennyiség</th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Birtokolt coinok jelenlegi értéke</th>
                                            
                                        </>
                                    )}
                                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-cream uppercase">Kimutatás</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-dark-blue divide-y divide-gray-600">
                                    {users.map((user, index) => ( 
                                        <tr key={index} className="hover:bg-light-blue">
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{index+1}</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.name} {user.admin ? '[ADMIN]' : null}</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.invested} $</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.share.toFixed(2)}%</td>
                                            {user.miningData && (
                                                <>
                                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.miningData.producedCoins}</td>
                                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.miningData.convertedCoins}</td>
                                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.miningData.convertedCoinValue}$</td>
                                                    
                                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.miningData.energyCost}$</td>
                                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.miningData.netCoins}</td>
                                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.miningData.currentPrice}$</td>
                                               </>
                                            )}
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                                <button onClick={() => openModal(user)} className="text-indigo-300 hover:underline">Kimutatás</button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                            </table>
                        </div>
                        }
                </div>
            )}
            </>
        )}
        </>
    )
}
