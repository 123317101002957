import React from "react";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { HumanSummarize } from "../../../../models/types/HumanSummarize";
import { getGroupSumData } from "../../../../api/getGroupSumInvested";

export default function Container() {

    const menu = useSelector(selectMenuStates)

    const [data, setData] = React.useState<HumanSummarize>();

    React.useEffect(() => {
        getGroupSumData(menu?.daisyMenu)
        .then((response) => {
            setData(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu])

    return (
        <>
            {menu?.daisyMenu && data && (
                <div className="bg-white px-2 py-2 shadow-gray-300 shadow-md">
                    <h1 className="text-2xl text-center mb-5">Csoport adatainak összesítése</h1>
                    <p className="py-1 text-xl">Csoport neve: <span className="font-semibold">{menu?.daisyMenu?.group_name}</span></p>
                    <p className="py-1 text-xl">Befizetett összeg: <span className="font-semibold">{data?.totalInvested?.toLocaleString('en-En').replaceAll(',', ' ')}$</span></p>

                    <div className="border px-2 py-2 mt-2">
                        <p className="py-1 text-xl">Jelenlegi likviditás: <span className="font-semibold">{data?.lastLiq.amount?.toLocaleString('en-En').replaceAll(',', ' ')}$</span></p>
                        <p className="py-1 text-xl">Bitcoin és Ethereum jelenlegi értéke: <span className="font-semibold">{data?.prices?.toLocaleString('en-En').replaceAll(',', ' ')}$</span></p>
                        <p className="py-1 text-xl">Altcoinok jelenlegi értéke: <span className="font-semibold">{data?.altCoinPrices?.toLocaleString('en-En').replaceAll(',', ' ')}$</span></p>
                    </div>

                    <p className="py-1 text-xl">Összesen: <span className="font-semibold">{data?.totalPortfolio?.toLocaleString('en-En').replaceAll(',', ' ')}$</span></p>
                </div>
            )}
        </>
    );
}