import { Crypto } from "../../../../models/crypto.model";
import { Asset } from "../../../../models/assets.model";
import { TradingHelper } from "../../../../models/tradinghelper.model";
import { getPercentage } from "./helperfunctions/getPercent";

type AssetsMenuProps = {
  selectedAsset: Asset | undefined;
  takenRecords: Crypto[];
  tradingHelperData: TradingHelper | undefined;
}

export default function AssetSellPanel({ selectedAsset, takenRecords, tradingHelperData } : AssetsMenuProps) {

  return (
    <>
      <div className="">    
            <h1 className="text-2xl font-semibold py-2">{selectedAsset?.name} : <span className="text-error">Eladás</span></h1>
            {(takenRecords.length > 0 && tradingHelperData) ? (
                <>
                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <h1 className="text-2xl text-center">Rendelkezésre álló coin darabszám: <span className="text-brown">{parseFloat(tradingHelperData.fixedCoinQuantity).toFixed(selectedAsset?.alt ? 2 : 8)} (db)</span></h1>
                
                    <div className="flex space-x-4 px-2 py-2 items-center">
                        <ul className="px-2 py-2 bg-gray-100 rounded w-full flex flex-row space-xs-4 items-center">
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>I. eladási szint: A mennyiség: <span className="text-cream">{tradingHelperData.coin_lvl1}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(parseFloat(tradingHelperData.fixedCoinQuantity), tradingHelperData.coin_lvl1).toFixed(selectedAsset?.alt ? 2 : 8)} db
                                </p>
                            </li>
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>II. eladási szint: A mennyiség: <span className="text-cream">{tradingHelperData.coin_lvl2}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(parseFloat(tradingHelperData.fixedCoinQuantity), tradingHelperData.coin_lvl2).toFixed(selectedAsset?.alt ? 2 : 8)} db
                                </p>
                            </li>
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>III. eladási szint: A mennyiség: <span className="text-cream">{tradingHelperData.coin_lvl3}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(parseFloat(tradingHelperData.fixedCoinQuantity), tradingHelperData.coin_lvl3).toFixed(selectedAsset?.alt ? 2 : 8)} db
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="flex space-x-4 px-2 py-2 justfiy-center items-center mt-5">
                        <p className="text-xl"><span className="text-brown">{tradingHelperData.increasePercent}%-os</span> árfolyam-emelkedésnél az árfolyam: <span className="text-success font-semibold ">{(tradingHelperData.avg_transaction_rate * (1 + (tradingHelperData.increasePercent / 100))).toFixed(2)}$</span></p>
                    </div>
                </div>
                </>
            ) : (
                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <p className="text-xl">Jelenleg még nincsen konfigurálva a következő: <span className="font-semibold">{selectedAsset?.name}</span></p>
                </div>
            )}
        </div>
    </>
  );
}