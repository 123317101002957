import { MinedCoinsTrading } from "../models/minedcoinstrading.model";
import { MinedCoinsTransfer } from "../models/minedcoinstransfer.model";
import { responseHero } from "../utils/axiosInterceptor";

export type ExtendedResposne = {
    history: MinedCoinsTransfer[];
    total: number;
}
export const getMinedCoinsTransferHistory = () : Promise<ExtendedResposne> => {
    return new Promise<ExtendedResposne>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/minedcoins/transfer/history`).then((response) => {
            resolve(response.data as ExtendedResposne);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export type MinedCoinsTradeWithTotals = {
    tradingList: MinedCoinsTrading[];
    totalLiq: number;
    totalQuantity: number;
}
export const getMinedCoinsTrading = () : Promise<MinedCoinsTradeWithTotals> => {
    return new Promise<MinedCoinsTradeWithTotals>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/minedcoinstrade`).then((response) => {
            resolve(response.data as MinedCoinsTradeWithTotals);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};