import React, { useEffect } from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getMyCryptos } from "../../../../../api/getMyCryptos";
import { getDepositRecords } from "../../../../../api/getHumanDCFunctions";
import { Group } from "../../../../../models/group.model";
import { DepositMetaData } from "../../../../../types/humandc";
import { selectUser } from "../../../../../store/reducers/userSlice";
import { responseHero } from "../../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { group } from "console";


export default function FormContainer () {

    const [portfolioStates, setPortfolioStates] = React.useState<DepositMetaData[]>([]); // portfolio states
    const menu = useSelector(selectMenuStates)
    const user = useSelector(selectUser);
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const getPortfolio = React.useCallback(async (group: Group) => {
        setLoading(true)
        try {
            const deposits = await getDepositRecords(group);
            if (deposits.length > 0) {
                const portfolio = await getMyCryptos(group.group_id, deposits.map(deposit => deposit.id));
                if (portfolio) {
                    setPortfolioStates(portfolio.depositMetaData);
                }
            }
        }
        finally {
            setLoading(false)
        }
    }, []);
    

    const fetchPortfolioData = async () => {
        if(!menu?.daisyMenu) return;
        await getPortfolio(menu?.daisyMenu);
        await updateGroupLiquidity(menu?.daisyMenu);
    }

    const updateGroupLiquidity = async (group: Group) => {

        if(!group || portfolioStates.length === 0) return;

        const totalLiquidityAmout = portfolioStates.reduce((acc, curr) => acc += curr.liquidity, 0);
        console.log(group.group_id + ". group total liquidity: " + totalLiquidityAmout);

        const bodyRequest = {
            creatorId: user.userInfo.user_id,
            group_id: group.group_id,
            amount: totalLiquidityAmout
        }

        await responseHero.post(`${process.env.REACT_APP_API_URL}/liqhistory`, { record: bodyRequest }).then((response) => {
            toast.success(`Likviditás frissítve!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            toast.error(`Sikertelen frissítés!`);
        })
    }

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"

    return (
        <>
        <div className="flex items-center justify-center mt-2">
            <button disabled={loading} className="text-center text-xl px-2 py-2 bg-dark-brown text-white opacity-80 hover:opacity-100" onClick={fetchPortfolioData}>Adatok lekérdezése</button>
        </div>

        {loading ? <div className="m-5">Kérlek várj...</div> : (
        <div className="w-full overflow-x-auto text-white h-full">
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Befizetés dátuma</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Név</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Likviditás</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Százalékos megtérülés</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {portfolioStates.map((entity, index) => (
                            <tr key={entity.id} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{entity.date.toString().split('T')[0]}</td>
                                <td className={dataRowStyling}>{entity.name}</td>
                                <td className={dataRowStyling}>{entity.liquidity}</td>
                                <td className={`${dataRowStyling} ${entity.rate < 0 ? 'text-error' : 'text-success'}`}>{entity.rate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        )}
        </>
    )
}