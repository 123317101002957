import React from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getPhysicalGoldRecords } from "../../../../../api/getHumanDCFunctions";
import { PhysicalGold } from "../../../../../models/physicalgold.model";
import AddNewRecordModal from "../../../modals/gold/AddNewRecordModal";
import RemoveRecordModal from "../../../modals/gold/RemoveRecordModal";
import ModifyRecordModal from "../../../modals/gold/ModifyRecordModal";
import { Assignments } from "../../../../../types/assigments";

export default function FormContainer () {

    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showModifyRecordModal, setShowModifyRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [showRemoveRecordModal, setShowRemoveRecordModal] = React.useState<boolean>(false); // remove record modal state

    const [records, setRecords] = React.useState<PhysicalGold[]>([])
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)

    const [selectedRecord, setSelectedRecord] = React.useState<PhysicalGold>();

    const menu = useSelector(selectMenuStates)

    const openModal = () => {
        setShowNewRecordModal(true)
    };

    const openModifyRecordModal = (record: PhysicalGold) => {
        setSelectedRecord(record)
        setShowModifyRecordModal(true)
    };

    const openRemoveModal = (record: PhysicalGold) => {
        setSelectedRecord(record)
        setShowRemoveRecordModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowModifyRecordModal(false)
        setShowRemoveRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }
    
    React.useEffect(() => {
        getPhysicalGoldRecords(menu?.daisyMenu)
        .then((response) => {
            setRecords(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, recodsStateChange])

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    const validValue = "#E8D788";
    const defaultValue = "#D3D3D3";

    return (
        <>
        <div className="w-full overflow-x-auto text-white h-full">
            
        <AddNewRecordModal title={"Adatsor hozzárendelése"} group={menu.daisyMenu} showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <RemoveRecordModal title={"Adatsor törlése"} group={menu.daisyMenu} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
        <ModifyRecordModal title={"Adatsor módosítása"} group={menu.daisyMenu} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            <div className="text-center mt-5">
                <button className="text-md text-center p-2 bg-success shadow-lg rounded mb-2 hover:opacity-80" onClick={() => openModal()}>Új adatsor rögzítése</button>
            </div>

            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Összeg (EUR)</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Gramm ár (EUR)</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Vásárolt gramm mennyiség</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Jelenlegi érték (EUR)</th>
                           {/* <th className="border p-2 hover:opacity-80 cursor-pointer">Százalékos változás</th>*/}

                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Kezelés</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Törlés</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {records.map((record, index) => (
                            <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>{record.operation ? "BUY" : "SELL"}</td>
                                <td className={dataRowStyling}>{record.amount}</td>
                                <td className={dataRowStyling}>{record.price_per_gram}</td>
                                <td className={dataRowStyling}>{record.purchased_grams}</td>
                                <td className={dataRowStyling}>{record.current_value}</td>
                                {/*<td className={dataRowStyling}>{record.percentage_change}</td>*/}

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openModifyRecordModal(record)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Módosít</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openRemoveModal(record)
                                    }} className="text-error hover:opacity-80 text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}