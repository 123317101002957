import React from 'react';
import { getEnvelopeList } from '../../api/getEnvelopeList';
import { Envelope } from '../../models/envelope.model';
import { dateFormatter } from '../../utils/dateFormatter';
import { useNavigate } from 'react-router';
import { responseHero } from '../../utils/axiosInterceptor';
import { toast } from 'react-toastify';
interface ContractHistoryProps {
    archivedListOpen: boolean;
}
export default function ContractHistory ({ archivedListOpen } : ContractHistoryProps) {

    const [envelopes, setEnvelopes] = React.useState<Envelope[]>([])
    const [spinner, setSpinner] = React.useState<boolean>(false)
    const [stateChange, setStateChange] = React.useState<boolean>(true)
    const [archivedListOpenState, setArchivedListOpenState] = React.useState<boolean>(archivedListOpen)
    const navigate = useNavigate()

    /* Effects */
    React.useEffect(() => {
        getEnvelopeList(archivedListOpenState)
        .then((response) => {
            setEnvelopes(response)
            setSpinner(false)
        })
        .catch((error) => {
            console.error(error);
            setSpinner(false)
        });

    }, [stateChange, archivedListOpenState])
    
    const changeContractArchivedStatus = async (env: Envelope) => {
        await responseHero.put(`${process.env.REACT_APP_API_URL}/envelope/${env?.envelope_id}`, { archived: !archivedListOpen }).then((response) => {
            handleStateChange();
            toast.success(`Sikeresen változtattad a szerződés állapotát!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            toast.error(`Sikertelen módosítási kísérlet!`);
        })
    }

    const handleStateChange = () => {
        setStateChange(!stateChange)
    }

    return (
        <div className="m-20">
        <p className="text-2xl text-center text-dark-brown mb-5 font-bold">Kiküldött szerződések</p>

        <div className="flex flex-row items-center justify-center text-center mb-2 mt-2">
            <button onClick={() => setArchivedListOpenState(!archivedListOpenState)} className="py-3 px-2 bg-success rounded-lg shadow-md text-white hover:opacity-80">
                {archivedListOpenState ? "Vissza az élő szerződésekhez" : "Archivum megtekintése"}
            </button>
        </div>
        <div style={{ maxHeight: 600, overflowY: 'auto' }}>
        <div className="w-full border border-gray-600 rounded-lg shadow bg-dark-blue">
            <table className="w-full divide-y table-fixed divide-gray-600 shadow-lg">
                <thead className="bg-dark-blue sticky top-0">
                    <tr>
                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">ID</th>
                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Referencia szám</th>
                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Státusz</th>
                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Idő</th>
                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Ügyfél</th>
                        <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Összeg</th>
                        <th scope="col" className="sr-only">Kezelés</th>
                    </tr>
                </thead>
                <tbody className="bg-dark-blue divide-y divide-gray-600">
                    {envelopes.map((env, index) => ( 
                        <tr key={index} className="hover:bg-light-blue">
                            <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.envelope_id}</td>
                            <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.envelope_ref_id}</td>
                            <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.status}</td>
                            <td className="py-4 px-6 text-sm font-medium text-white text-center">{dateFormatter(env.statusDateTime.toString())}</td>
                            <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.signer.name} ({env.signer.email})</td>
                            <td className="py-4 px-6 text-sm font-medium text-white text-center">{env.invested_amount} {env.currency}</td>

                            <td >
                                <p onClick={() => {
                                    changeContractArchivedStatus(env)
                                }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">
                                    {archivedListOpen ? "Visszaállítás az archivumból" : "Archivumba helyezés"}
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
        </div>
        </div>
        {envelopes.length === 0 ? <p className='text-center mt-5 text-lg'>Még nincs előállított szerződés.</p> : null}
        </div>
    )
}
