import React, { useEffect, useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { DepositRegister } from "../../../../models/depositregister";
import { User } from "../../../../models/user.model";

type ModifiedPayload = Omit<DepositRegister, 'user'>;

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    user: User | undefined;
}

const AddNewRecordModal = ({ group, title, showModal, closeModal, handleStateChange, user } : ModalProps) => {

    const [investedAmount, setInvestedAmount] = useState(0);

    const updateRecord = async () => {

        if(!group || !user) return;

        await responseHero.post(`${process.env.REACT_APP_API_URL}/humandc/deposit`, { group_id: group.group_id, user_id: user.user_id, invested_amount: investedAmount }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen hozzáadtál egy rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                    <div className="w-full pr-2 text-center justify-center items-center">
                                        <h2 className="mb-2 text-center text-lg text-gray-800">Új beutalás rögzítése neki: <span className="text-brown">{user?.name}</span></h2>
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Befizetett összeg </span> <br/>EUR
                                        </label>
                                        <input
                                            value={investedAmount}
                                            onChange={(e) => setInvestedAmount(parseInt(e.target.value, 10))}
                                            className="text-center shadow appearance-none border rounded w-min mt-2 shadow-lg py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Befizetett összeg EUR-ban"/>
                                    </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={updateRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewRecordModal;