import { responseHero } from "../utils/axiosInterceptor";
import { Response } from "../types/humandc";

export const getMyCryptos = (
    group_id: number,
    deposit_ids: number[]
): Promise<Response> => {
    return new Promise<Response>((resolve, reject) => {

        const url = `${process.env.REACT_APP_API_URL}/mycryptos/${group_id}?deposit_ids=${deposit_ids.join(',')}`;

        responseHero.get(url)
            .then((response) => {
                resolve(response.data as Response);
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    });
};